import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import { PlayerStats } from "../../models/PlayerStats";
import { PlayerInfos } from "../../models/utils/PlayerInfos";
import { PositionPlayer, StatPlayer } from "../../models/utils/PositionPlayer";
import FullScreenSvg from "../Images/FullScreenSvg";
import PlayerImg from "../PlayerImg/PlayerImg";
import './StatsPlayers.scss';

interface IProps{
    currentPosition: PositionPlayer,
    playerInfos: PlayerInfos[]
}
export default function StatsPlayer({currentPosition, playerInfos}: IProps){
    
    return <>
        <TableContainer>
            <Table sx={{ maxWidth: 650 }} size="small">
                <TableHead>
                <TableRow>
                    <TableCell></TableCell> {/* index column */}
                    <TableCell></TableCell> {/* label column */}
                    {/* players columns */}
                    {playerInfos.map((playerInfo, index) => {
                            return (
                                <TableCell align="center">
                                    <PlayerImg player={playerInfo.player} squareWidth={30} index={index}/>
                                </TableCell>
                            )
                    })}
                    <TableCell></TableCell> {/* players % column */}
                    <TableCell><FullScreenSvg/></TableCell> {/* best player / full screen column */}
                </TableRow>
                </TableHead>
                <TableBody>
                    {currentPosition.avalaibleStatPlayer.map( (stat: StatPlayer, key: number) => {
                        const bestPlayerImage = () => {
                            let bestPlayer = playerInfos.reduce((acc, value, index) => {
                                console.log(stat.best)
                                if (stat.best === 'max') {
                                    return (acc = acc.stats[stat.name as keyof PlayerStats] > value.stats[stat.name as keyof PlayerStats] ? acc : value);
                                } else {
                                    return (acc = acc.stats[stat.name as keyof PlayerStats] < value.stats[stat.name as keyof PlayerStats] ? acc : value);
                                }
                            }, playerInfos[0]);
                            return (
                                <PlayerImg player={bestPlayer.player} squareWidth={30} index={playerInfos.findIndex(playerInfo => playerInfo.player.id === bestPlayer.player.id)}/>
                            )
                        }
                        const repartitionBar = () => {
                            let total = playerInfos.reduce((acc, value) => {
                                return (acc = acc + value.stats[stat.name as keyof PlayerStats]);
                            }, 0);
                            const getRatio = (stat: number) => {
                                return (stat / total) * 100
                            }
                            return (
                                <div className="repartition-bar">
                                    {playerInfos.map((playerInfo, index) =>
                                        <Tooltip title={`${playerInfo.stats[stat.name as keyof PlayerStats]}`} placement="top-end">
                                            <div key={`${playerInfo.player.id}_purcent`} style={{width:`${getRatio(playerInfo.stats[stat.name as keyof PlayerStats])}%`, backgroundColor: playerInfo.player.getColorByIndex(index), height: '100%'}}>
                                            </div>
                                        </Tooltip>
                                    )}
                                </div>
                            )
                        }
                        return (
                            <TableRow
                                key={stat.name}
                            >
                                <TableCell align="center">{key}</TableCell> {/* index column */}
                                <TableCell align="left">{stat.label}</TableCell> {/* label column */}
                                {playerInfos.map((playerInfo) => {
                                    return (
                                        <TableCell align="center" key={`stat_${playerInfo.player.id}_key_${key}`}> {/* players columns */}
                                            {playerInfo.stats[stat.name as keyof PlayerStats]}
                                        </TableCell>
                                    )
                                })}
                                <TableCell align="center" sx={{ minWidth: 150, width: '100%' }}>{repartitionBar()}</TableCell> {/* players % column */}
                                <TableCell align="center">{bestPlayerImage()}</TableCell> {/* best player / full screen column */}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    </>
}