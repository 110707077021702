import { CircularProgress } from "@mui/material";
import "./Loader.scss";

interface IProps {
  size?: number;
}

function Loader({ size = 35 }: IProps) {
  return (
    <div className="loader-container">
      <CircularProgress className="loader" size={size} />
    </div>
  );
}

export default Loader;
