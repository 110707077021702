import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import SelectPlayerSvg from "../../Images/SelectPlayerSvg";
import './EmptyStatsPlayers.scss';

export default function EmptyStatsPlayers(){
    
    return (
        <div className="empty-stats-container">
            <div className="text-container">
                <SelectPlayerSvg/>
                <p>Il n’y a pas de données à afficher.</p>
                <p>Merci de sélectionner des joueurs afin d’afficher les résultats</p>
            </div>
        </div>
    )
}