import { Player } from "../Player";
import { PlayerStats } from "../PlayerStats";

export class PlayerInfos{
    player: Player;
    stats: PlayerStats;

    constructor(data: any){
        this.player = data.player;
        this.stats = data.stats;
    }
}