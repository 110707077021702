import "./Header.scss";

interface IProps{
  title: string;
}
export default function Header({title}: IProps) {
  return (
    <header>
      <div className="header-start">
        <div className="header-breadcrumb">
          <div
            key={`header-breadcrumb-item`}
            className="header-breadcrumb-item"
          >
            {title}
          </div>
        </div>
      </div>

      <div className="header-end"></div>
    </header>
  );
}
