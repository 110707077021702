// @@ interfaces
import IDropdown from "./Interfaces/IDropdown.interface";
import IDropdownItem from "./Interfaces/IDropdownItem.interface";

// @@ react
import { useEffect, useState } from "react";

// @@ style
import "./Dropdown.scss";

export default function Dropdown({
  dropdownAlign = "center",
  dropdownItems,
  dropdownItemsType,
  isDropdownVisible = false,
  onDropdownItemClick,
  onSelectedDropdownItemsChange,
}: IDropdown) {
  const [selectedDropdownItems, setSelectedDropdownItems] = useState<
    IDropdownItem[]
  >([]);

  useEffect(() => {
    onSelectedDropdownItemsChange?.(selectedDropdownItems);
  }, [selectedDropdownItems]);

  function isDropdownItemSelected(dropdownItem: IDropdownItem) {
    return selectedDropdownItems
      .map(({ key }: IDropdownItem) => key)
      .includes(dropdownItem.key);
  }

  function handleDropdownItemClick(dropdownItem: IDropdownItem) {
    handleSelection: {
      const selectedDropdownItemsCopy = [...selectedDropdownItems];

      if (isDropdownItemSelected(dropdownItem)) {
        const index = selectedDropdownItemsCopy
          .map(({ key }: IDropdownItem) => key)
          .indexOf(dropdownItem.key);
        selectedDropdownItemsCopy.splice(index, 1);
      } else selectedDropdownItemsCopy.push(dropdownItem);

      setSelectedDropdownItems(selectedDropdownItemsCopy);
    }

    handleCustomAction: {
      onDropdownItemClick?.(dropdownItem);
    }
  }

  return (
    <div
      className="dropdown"
      data-dropdown-align={dropdownAlign}
      data-is-dropdown-visible={isDropdownVisible}
      data-dropdown-items-type={dropdownItemsType}
    >
      {dropdownItems.length
        ? dropdownItems.map((dropdownItem: IDropdownItem, index: number) => {
            const { value } = dropdownItem;

            return (
              <div
                className="dropdown-item"
                key={`dropdown-item-${index}`}
                onClick={() => handleDropdownItemClick(dropdownItem)}
                data-is-selected={isDropdownItemSelected(dropdownItem)}
              >
                <span className="dropdown-item-text">{value}</span>
              </div>
            );
          })
        : `Cette liste est vide`}
    </div>
  );
}
