import axios from "axios";
import { Player } from "../models/Player";

async function list(search: string){
    const res = await axios.get(`${process.env.REACT_APP_SKRINNERS_API}/api/v1/players?search=${search}`)
    
    return res.data.data.map((item: any) => new Player(item));
}

export default {
    list
}