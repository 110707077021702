export class PlayerStats{
    average_accelerations: number;
    average_aerialDuels: number;
    average_assists: number;
    average_attackingActions: number;
    average_backPasses: number;
    average_ballLosses: number;
    average_ballRecoveries: number;
    average_clearances: number;
    average_corners: number;
    average_counterpressingRecoveries: number;
    average_crosses: number;
    average_dangerousOpponentHalfRecoveries: number;
    average_dangerousOwnHalfLosses: number;
    average_defensiveActions: number;
    average_defensiveDuels: number;
    average_defensiveDuelsWon: number;
    average_directFreeKicks: number;
    average_directFreeKicksOnTarget: number;
    average_directRedCards: number;
    average_dribbleDistanceFromOpponentGoal: number;
    average_dribbles: number;
    average_dribblesAgainst: number;
    average_dribblesAgainstWon: number;
    average_duels: number;
    average_duelsWon: number;
    average_fieldAerialDuels: number;
    average_fieldAerialDuelsWon: number;
    average_forwardPasses: number;
    average_fouls: number;
    average_foulsSuffered: number;
    average_freeKicks: number;
    average_freeKicksOnTarget: number;
    average_gkAerialDuels: number;
    average_gkAerialDuelsWon: number;
    average_gkConcededGoals: number;
    average_gkExits: number;
    average_gkSaves: number;
    average_gkShotsAgainst: number;
    average_gkSuccessfulExits: number;
    average_goalKicks: number;
    average_goalKicksLong: number;
    average_goalKicksShort: number;
    average_goals: number;
    average_headShots: number;
    average_interceptions: number;
    average_keyPasses: number;
    average_lateralPasses: number;
    average_linkupPlays: number;
    average_longPassLength: number;
    average_longPasses: number;
    average_looseBallDuels: number;
    average_looseBallDuelsWon: number;
    average_losses: number;
    average_missedBalls: number;
    average_newDefensiveDuelsWon: number;
    average_newDuelsWon: number;
    average_newOffensiveDuelsWon: number;
    average_newSuccessfulDribbles: number;
    average_offensiveDuels: number;
    average_offensiveDuelsWon: number;
    average_offsides: number;
    average_opponentHalfRecoveries: number;
    average_ownHalfLosses: number;
    average_passLength: number;
    average_passes: number;
    average_passesToFinalThird: number;
    average_penalties: number;
    average_progressivePasses: number;
    average_progressiveRun: number;
    average_receivedPass: number;
    average_redCards: number;
    average_secondAssists: number;
    average_shotAssists: number;
    average_shotOnTargetAssists: number;
    average_shots: number;
    average_shotsBlocked: number;
    average_shotsOnTarget: number;
    average_slidingTackles: number;
    average_smartPasses: number;
    average_successfulAttackingActions: number;
    average_successfulBackPasses: number;
    average_successfulCrosses: number;
    average_successfulDefensiveAction: number;
    average_successfulDribbles: number;
    average_successfulForwardPasses: number;
    average_successfulGoalKicks: number;
    average_successfulKeyPasses: number;
    average_successfulLateralPasses: number;
    average_successfulLinkupPlays: number;
    average_successfulLongPasses: number;
    average_successfulPasses: number;
    average_successfulPassesToFinalThird: number;
    average_successfulPenalties: number;
    average_successfulProgressivePasses: number;
    average_successfulSlidingTackles: number;
    average_successfulSmartPasses: number;
    average_successfulThroughPasses: number;
    average_successfulVerticalPasses: number;
    average_thirdAssists: number;
    average_throughPasses: number;
    average_touchInBox: number;
    average_verticalPasses: number;
    average_xgAssist: number;
    average_xgSave: number;
    average_xgShot: number;
    average_yellowCards: number;
    percent_aerialDuelsWon: number;
    percent_defensiveDuelsWon: number;
    percent_directFreeKicksOnTarget: number;
    percent_dribblesAgainstWon: number;
    percent_duelsWon: number;
    percent_fieldAerialDuelsWon: number;
    percent_gkAerialDuelsWon: number;
    percent_gkSaves: number;
    percent_gkSuccessfulExits: number;
    percent_goalConversion: number;
    percent_headShotsOnTarget: number;
    percent_newDefensiveDuelsWon: number;
    percent_newDuelsWon: number;
    percent_newOffensiveDuelsWon: number;
    percent_newSuccessfulDribbles: number;
    percent_offensiveDuelsWon: number;
    percent_penaltiesConversion: number;
    percent_shotsOnTarget: number;
    percent_successfulBackPasses: number;
    percent_successfulCrosses: number;
    percent_successfulDribbles: number;
    percent_successfulForwardPasses: number;
    percent_successfulGoalKicks: number;
    percent_successfulKeyPasses: number;
    percent_successfulLateralPasses: number;
    percent_successfulLinkupPlays: number;
    percent_successfulLongPasses: number;
    percent_successfulPasses: number;
    percent_successfulPassesToFinalThird: number;
    percent_successfulProgressivePasses: number;
    percent_successfulShotAssists: number;
    percent_successfulSlidingTackles: number;
    percent_successfulSmartPasses: number;
    percent_successfulThroughPasses: number;
    percent_successfulVerticalPasses: number;
    percent_win: number;
    percent_yellowCardsPerFoul: number;
    total_accelerations: number;
    total_aerialDuels: number;
    total_aerialDuelsWon: number;
    total_assists: number;
    total_attackingActions: number;
    total_backPasses: number;
    total_clearances: number;
    total_corners: number;
    total_counterpressingRecoveries: number;
    total_crosses: number;
    total_dangerousOpponentHalfRecoveries: number;
    total_dangerousOwnHalfLosses: number;
    total_defensiveActions: number;
    total_defensiveDuels: number;
    total_defensiveDuelsWon: number;
    total_directFreeKicks: number;
    total_directFreeKicksOnTarget: number;
    total_directRedCards: number;
    total_dribbles: number;
    total_dribblesAgainst: number;
    total_dribblesAgainstWon: number;
    total_duels: number;
    total_duelsWon: number;
    total_fieldAerialDuels: number;
    total_fieldAerialDuelsWon: number;
    total_forwardPasses: number;
    total_fouls: number;
    total_foulsSuffered: number;
    total_freeKicks: number;
    total_freeKicksOnTarget: number;
    total_gkAerialDuels: number;
    total_gkAerialDuelsWon: number;
    total_gkCleanSheets: number;
    total_gkConcededGoals: number;
    total_gkExits: number;
    total_gkSaves: number;
    total_gkShotsAgainst: number;
    total_gkSuccessfulExits: number;
    total_goalKicks: number;
    total_goalKicksLong: number;
    total_goalKicksShort: number;
    total_goals: number;
    total_headShots: number;
    total_interceptions: number;
    total_keyPasses: number;
    total_lateralPasses: number;
    total_linkupPlays: number;
    total_longPasses: number;
    total_looseBallDuels: number;
    total_looseBallDuelsWon: number;
    total_losses: number;
    total_matches: number;
    total_matchesComingOff: number;
    total_matchesInStart: number;
    total_matchesSubstituted: number;
    total_minutesOnField: number;
    total_minutesTagged: number;
    total_missedBalls: number;
    total_newDefensiveDuelsWon: number;
    total_newDuelsWon: number;
    total_newOffensiveDuelsWon: number;
    total_newSuccessfulDribbles: number;
    total_offensiveDuels: number;
    total_offensiveDuelsWon: number;
    total_offsides: number;
    total_opponentHalfRecoveries: number;
    total_ownHalfLosses: number;
    total_passes: number;
    total_passesToFinalThird: number;
    total_penalties: number;
    total_pressingDuels: number;
    total_pressingDuelsWon: number;
    total_progressivePasses: number;
    total_progressiveRun: number;
    total_receivedPass: number;
    total_recoveries: number;
    total_redCards: number;
    total_secondAssists: number;
    total_shotAssists: number;
    total_shotOnTargetAssists: number;
    total_shots: number;
    total_shotsBlocked: number;
    total_shotsOnTarget: number;
    total_slidingTackles: number;
    total_smartPasses: number;
    total_successfulAttackingActions: number;
    total_successfulBackPasses: number;
    total_successfulCrosses: number;
    total_successfulDefensiveAction: number;
    total_successfulDribbles: number;
    total_successfulForwardPasses: number;
    total_successfulGoalKicks: number;
    total_successfulKeyPasses: number;
    total_successfulLateralPasses: number;
    total_successfulLinkupPlays: number;
    total_successfulLongPasses: number;
    total_successfulPasses: number;
    total_successfulPassesToFinalThird: number;
    total_successfulPenalties: number;
    total_successfulProgressivePasses: number;
    total_successfulSlidingTackles: number;
    total_successfulSmartPasses: number;
    total_successfulThroughPasses: number;
    total_successfulVerticalPasses: number;
    total_thirdAssists: number;
    total_throughPasses: number;
    total_touchInBox: number;
    total_verticalPasses: number;
    total_xgAssist: number;
    total_xgSave: number;
    total_xgShot: number;
    total_yellowCards: number;

    // GENERATED STATS (sums)
    total_actions: number;
    total_successfulActions: number;

    constructor(data: any){
        console.log(data)
        this.average_accelerations = data.average_accelerations;
        this.average_aerialDuels = data.average_aerialDuels;
        this.average_assists = data.average_assists;
        this.average_attackingActions = data.average_attackingActions;
        this.average_backPasses = data.average_backPasses;
        this.average_ballLosses = data.average_ballLosses;
        this.average_ballRecoveries = data.average_ballRecoveries;
        this.average_clearances = data.average_clearances;
        this.average_corners = data.average_corners;
        this.average_counterpressingRecoveries = data.average_counterpressingRecoveries;
        this.average_crosses = data.average_crosses;
        this.average_dangerousOpponentHalfRecoveries = data.average_dangerousOpponentHalfRecoveries;
        this.average_dangerousOwnHalfLosses = data.average_dangerousOwnHalfLosses;
        this.average_defensiveActions = data.average_defensiveActions;
        this.average_defensiveDuels = data.average_defensiveDuels;
        this.average_defensiveDuelsWon = data.average_defensiveDuelsWon;
        this.average_directFreeKicks = data.average_directFreeKicks;
        this.average_directFreeKicksOnTarget = data.average_directFreeKicksOnTarget;
        this.average_directRedCards = data.average_directRedCards;
        this.average_dribbleDistanceFromOpponentGoal = data.average_dribbleDistanceFromOpponentGoal;
        this.average_dribbles = data.average_dribbles;
        this.average_dribblesAgainst = data.average_dribblesAgainst;
        this.average_dribblesAgainstWon = data.average_dribblesAgainstWon;
        this.average_duels = data.average_duels;
        this.average_duelsWon = data.average_duelsWon;
        this.average_fieldAerialDuels = data.average_fieldAerialDuels;
        this.average_fieldAerialDuelsWon = data.average_fieldAerialDuelsWon;
        this.average_forwardPasses = data.average_forwardPasses;
        this.average_fouls = data.average_fouls;
        this.average_foulsSuffered = data.average_foulsSuffered;
        this.average_freeKicks = data.average_freeKicks;
        this.average_freeKicksOnTarget = data.average_freeKicksOnTarget;
        this.average_gkAerialDuels = data.average_gkAerialDuels;
        this.average_gkAerialDuelsWon = data.average_gkAerialDuelsWon;
        this.average_gkConcededGoals = data.average_gkConcededGoals;
        this.average_gkExits = data.average_gkExits;
        this.average_gkSaves = data.average_gkSaves;
        this.average_gkShotsAgainst = data.average_gkShotsAgainst;
        this.average_gkSuccessfulExits = data.average_gkSuccessfulExits;
        this.average_goalKicks = data.average_goalKicks;
        this.average_goalKicksLong = data.average_goalKicksLong;
        this.average_goalKicksShort = data.average_goalKicksShort;
        this.average_goals = data.average_goals;
        this.average_headShots = data.average_headShots;
        this.average_interceptions = data.average_interceptions;
        this.average_keyPasses = data.average_keyPasses;
        this.average_lateralPasses = data.average_lateralPasses;
        this.average_linkupPlays = data.average_linkupPlays;
        this.average_longPassLength = data.average_longPassLength;
        this.average_longPasses = data.average_longPasses;
        this.average_looseBallDuels = data.average_looseBallDuels;
        this.average_looseBallDuelsWon = data.average_looseBallDuelsWon;
        this.average_losses = data.average_losses;
        this.average_missedBalls = data.average_missedBalls;
        this.average_newDefensiveDuelsWon = data.average_newDefensiveDuelsWon;
        this.average_newDuelsWon = data.average_newDuelsWon;
        this.average_newOffensiveDuelsWon = data.average_newOffensiveDuelsWon;
        this.average_newSuccessfulDribbles = data.average_newSuccessfulDribbles;
        this.average_offensiveDuels = data.average_offensiveDuels;
        this.average_offensiveDuelsWon = data.average_offensiveDuelsWon;
        this.average_offsides = data.average_offsides;
        this.average_opponentHalfRecoveries = data.average_opponentHalfRecoveries;
        this.average_ownHalfLosses = data.average_ownHalfLosses;
        this.average_passLength = data.average_passLength;
        this.average_passes = data.average_passes;
        this.average_passesToFinalThird = data.average_passesToFinalThird;
        this.average_penalties = data.average_penalties;
        this.average_progressivePasses = data.average_progressivePasses;
        this.average_progressiveRun = data.average_progressiveRun;
        this.average_receivedPass = data.average_receivedPass;
        this.average_redCards = data.average_redCards;
        this.average_secondAssists = data.average_secondAssists;
        this.average_shotAssists = data.average_shotAssists;
        this.average_shotOnTargetAssists = data.average_shotOnTargetAssists;
        this.average_shots = data.average_shots;
        this.average_shotsBlocked = data.average_shotsBlocked;
        this.average_shotsOnTarget = data.average_shotsOnTarget;
        this.average_slidingTackles = data.average_slidingTackles;
        this.average_smartPasses = data.average_smartPasses;
        this.average_successfulAttackingActions = data.average_successfulAttackingActions;
        this.average_successfulBackPasses = data.average_successfulBackPasses;
        this.average_successfulCrosses = data.average_successfulCrosses;
        this.average_successfulDefensiveAction = data.average_successfulDefensiveAction;
        this.average_successfulDribbles = data.average_successfulDribbles;
        this.average_successfulForwardPasses = data.average_successfulForwardPasses;
        this.average_successfulGoalKicks = data.average_successfulGoalKicks;
        this.average_successfulKeyPasses = data.average_successfulKeyPasses;
        this.average_successfulLateralPasses = data.average_successfulLateralPasses;
        this.average_successfulLinkupPlays = data.average_successfulLinkupPlays;
        this.average_successfulLongPasses = data.average_successfulLongPasses;
        this.average_successfulPasses = data.average_successfulPasses;
        this.average_successfulPassesToFinalThird = data.average_successfulPassesToFinalThird;
        this.average_successfulPenalties = data.average_successfulPenalties;
        this.average_successfulProgressivePasses = data.average_successfulProgressivePasses;
        this.average_successfulSlidingTackles = data.average_successfulSlidingTackles;
        this.average_successfulSmartPasses = data.average_successfulSmartPasses;
        this.average_successfulThroughPasses = data.average_successfulThroughPasses;
        this.average_successfulVerticalPasses = data.average_successfulVerticalPasses;
        this.average_thirdAssists = data.average_thirdAssists;
        this.average_throughPasses = data.average_throughPasses;
        this.average_touchInBox = data.average_touchInBox;
        this.average_verticalPasses = data.average_verticalPasses;
        this.average_xgAssist = data.average_xgAssist;
        this.average_xgSave = data.average_xgSave;
        this.average_xgShot = data.average_xgShot;
        this.average_yellowCards = data.average_yellowCards;
        this.percent_aerialDuelsWon = data.percent_aerialDuelsWon;
        this.percent_defensiveDuelsWon = data.percent_defensiveDuelsWon;
        this.percent_directFreeKicksOnTarget = data.percent_directFreeKicksOnTarget;
        this.percent_dribblesAgainstWon = data.percent_dribblesAgainstWon;
        this.percent_duelsWon = data.percent_duelsWon;
        this.percent_fieldAerialDuelsWon = data.percent_fieldAerialDuelsWon;
        this.percent_gkAerialDuelsWon = data.percent_gkAerialDuelsWon;
        this.percent_gkSaves = data.percent_gkSaves;
        this.percent_gkSuccessfulExits = data.percent_gkSuccessfulExits;
        this.percent_goalConversion = data.percent_goalConversion;
        this.percent_headShotsOnTarget = data.percent_headShotsOnTarget;
        this.percent_newDefensiveDuelsWon = data.percent_newDefensiveDuelsWon;
        this.percent_newDuelsWon = data.percent_newDuelsWon;
        this.percent_newOffensiveDuelsWon = data.percent_newOffensiveDuelsWon;
        this.percent_newSuccessfulDribbles = data.percent_newSuccessfulDribbles;
        this.percent_offensiveDuelsWon = data.percent_offensiveDuelsWon;
        this.percent_penaltiesConversion = data.percent_penaltiesConversion;
        this.percent_shotsOnTarget = data.percent_shotsOnTarget;
        this.percent_successfulBackPasses = data.percent_successfulBackPasses;
        this.percent_successfulCrosses = data.percent_successfulCrosses;
        this.percent_successfulDribbles = data.percent_successfulDribbles;
        this.percent_successfulForwardPasses = data.percent_successfulForwardPasses;
        this.percent_successfulGoalKicks = data.percent_successfulGoalKicks;
        this.percent_successfulKeyPasses = data.percent_successfulKeyPasses;
        this.percent_successfulLateralPasses = data.percent_successfulLateralPasses;
        this.percent_successfulLinkupPlays = data.percent_successfulLinkupPlays;
        this.percent_successfulLongPasses = data.percent_successfulLongPasses;
        this.percent_successfulPasses = data.percent_successfulPasses;
        this.percent_successfulPassesToFinalThird = data.percent_successfulPassesToFinalThird;
        this.percent_successfulProgressivePasses = data.percent_successfulProgressivePasses;
        this.percent_successfulShotAssists = data.percent_successfulShotAssists;
        this.percent_successfulSlidingTackles = data.percent_successfulSlidingTackles;
        this.percent_successfulSmartPasses = data.percent_successfulSmartPasses;
        this.percent_successfulThroughPasses = data.percent_successfulThroughPasses;
        this.percent_successfulVerticalPasses = data.percent_successfulVerticalPasses;
        this.percent_win = data.percent_win;
        this.percent_yellowCardsPerFoul = data.percent_yellowCardsPerFoul;
        this.total_accelerations = data.total_accelerations;
        this.total_aerialDuels = data.total_aerialDuels;
        this.total_aerialDuelsWon = data.total_aerialDuelsWon;
        this.total_assists = data.total_assists;
        this.total_attackingActions = data.total_attackingActions;
        this.total_backPasses = data.total_backPasses;
        this.total_clearances = data.total_clearances;
        this.total_corners = data.total_corners;
        this.total_counterpressingRecoveries = data.total_counterpressingRecoveries;
        this.total_crosses = data.total_crosses;
        this.total_dangerousOpponentHalfRecoveries = data.total_dangerousOpponentHalfRecoveries;
        this.total_dangerousOwnHalfLosses = data.total_dangerousOwnHalfLosses;
        this.total_defensiveActions = data.total_defensiveActions;
        this.total_defensiveDuels = data.total_defensiveDuels;
        this.total_defensiveDuelsWon = data.total_defensiveDuelsWon;
        this.total_directFreeKicks = data.total_directFreeKicks;
        this.total_directFreeKicksOnTarget = data.total_directFreeKicksOnTarget;
        this.total_directRedCards = data.total_directRedCards;
        this.total_dribbles = data.total_dribbles;
        this.total_dribblesAgainst = data.total_dribblesAgainst;
        this.total_dribblesAgainstWon = data.total_dribblesAgainstWon;
        this.total_duels = data.total_duels;
        this.total_duelsWon = data.total_duelsWon;
        this.total_fieldAerialDuels = data.total_fieldAerialDuels;
        this.total_fieldAerialDuelsWon = data.total_fieldAerialDuelsWon;
        this.total_forwardPasses = data.total_forwardPasses;
        this.total_fouls = data.total_fouls;
        this.total_foulsSuffered = data.total_foulsSuffered;
        this.total_freeKicks = data.total_freeKicks;
        this.total_freeKicksOnTarget = data.total_freeKicksOnTarget;
        this.total_gkAerialDuels = data.total_gkAerialDuels;
        this.total_gkAerialDuelsWon = data.total_gkAerialDuelsWon;
        this.total_gkCleanSheets = data.total_gkCleanSheets;
        this.total_gkConcededGoals = data.total_gkConcededGoals;
        this.total_gkExits = data.total_gkExits;
        this.total_gkSaves = data.total_gkSaves;
        this.total_gkShotsAgainst = data.total_gkShotsAgainst;
        this.total_gkSuccessfulExits = data.total_gkSuccessfulExits;
        this.total_goalKicks = data.total_goalKicks;
        this.total_goalKicksLong = data.total_goalKicksLong;
        this.total_goalKicksShort = data.total_goalKicksShort;
        this.total_goals = data.total_goals;
        this.total_headShots = data.total_headShots;
        this.total_interceptions = data.total_interceptions;
        this.total_keyPasses = data.total_keyPasses;
        this.total_lateralPasses = data.total_lateralPasses;
        this.total_linkupPlays = data.total_linkupPlays;
        this.total_longPasses = data.total_longPasses;
        this.total_looseBallDuels = data.total_looseBallDuels;
        this.total_looseBallDuelsWon = data.total_looseBallDuelsWon;
        this.total_losses = data.total_losses;
        this.total_matches = data.total_matches;
        this.total_matchesComingOff = data.total_matchesComingOff;
        this.total_matchesInStart = data.total_matchesInStart;
        this.total_matchesSubstituted = data.total_matchesSubstituted;
        this.total_minutesOnField = data.total_minutesOnField;
        this.total_minutesTagged = data.total_minutesTagged;
        this.total_missedBalls = data.total_missedBalls;
        this.total_newDefensiveDuelsWon = data.total_newDefensiveDuelsWon;
        this.total_newDuelsWon = data.total_newDuelsWon;
        this.total_newOffensiveDuelsWon = data.total_newOffensiveDuelsWon;
        this.total_newSuccessfulDribbles = data.total_newSuccessfulDribbles;
        this.total_offensiveDuels = data.total_offensiveDuels;
        this.total_offensiveDuelsWon = data.total_offensiveDuelsWon;
        this.total_offsides = data.total_offsides;
        this.total_opponentHalfRecoveries = data.total_opponentHalfRecoveries;
        this.total_ownHalfLosses = data.total_ownHalfLosses;
        this.total_passes = data.total_passes;
        this.total_passesToFinalThird = data.total_passesToFinalThird;
        this.total_penalties = data.total_penalties;
        this.total_pressingDuels = data.total_pressingDuels;
        this.total_pressingDuelsWon = data.total_pressingDuelsWon;
        this.total_progressivePasses = data.total_progressivePasses;
        this.total_progressiveRun = data.total_progressiveRun;
        this.total_receivedPass = data.total_receivedPass;
        this.total_recoveries = data.total_recoveries;
        this.total_redCards = data.total_redCards;
        this.total_secondAssists = data.total_secondAssists;
        this.total_shotAssists = data.total_shotAssists;
        this.total_shotOnTargetAssists = data.total_shotOnTargetAssists;
        this.total_shots = data.total_shots;
        this.total_shotsBlocked = data.total_shotsBlocked;
        this.total_shotsOnTarget = data.total_shotsOnTarget;
        this.total_slidingTackles = data.total_slidingTackles;
        this.total_smartPasses = data.total_smartPasses;
        this.total_successfulAttackingActions = data.total_successfulAttackingActions;
        this.total_successfulBackPasses = data.total_successfulBackPasses;
        this.total_successfulCrosses = data.total_successfulCrosses;
        this.total_successfulDefensiveAction = data.total_successfulDefensiveAction;
        this.total_successfulDribbles = data.total_successfulDribbles;
        this.total_successfulForwardPasses = data.total_successfulForwardPasses;
        this.total_successfulGoalKicks = data.total_successfulGoalKicks;
        this.total_successfulKeyPasses = data.total_successfulKeyPasses;
        this.total_successfulLateralPasses = data.total_successfulLateralPasses;
        this.total_successfulLinkupPlays = data.total_successfulLinkupPlays;
        this.total_successfulLongPasses = data.total_successfulLongPasses;
        this.total_successfulPasses = data.total_successfulPasses;
        this.total_successfulPassesToFinalThird = data.total_successfulPassesToFinalThird;
        this.total_successfulPenalties = data.total_successfulPenalties;
        this.total_successfulProgressivePasses = data.total_successfulProgressivePasses;
        this.total_successfulSlidingTackles = data.total_successfulSlidingTackles;
        this.total_successfulSmartPasses = data.total_successfulSmartPasses;
        this.total_successfulThroughPasses = data.total_successfulThroughPasses;
        this.total_successfulVerticalPasses = data.total_successfulVerticalPasses;
        this.total_thirdAssists = data.total_thirdAssists;
        this.total_throughPasses = data.total_throughPasses;
        this.total_touchInBox = data.total_touchInBox;
        this.total_verticalPasses = data.total_verticalPasses;
        this.total_xgAssist = data.total_xgAssist;
        this.total_xgSave = data.total_xgSave;
        this.total_xgShot = data.total_xgShot;
        this.total_yellowCards = data.total_yellowCards;
        
        // GENERATED STATS (sums)
        this.total_actions = data.total_defensiveActions + data.total_attackingActions;
        this.total_successfulActions = data.total_successfulDefensiveActions + data.total_successfulAttackingActions;
    }
}
    