import * as React from "react"
import { SVGProps } from "react"

const SelectPlayerSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={57}
    height={57}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={4.5} y={4.422} width={48} height={48} rx={24} fill="#FED87D" />
    <path
      d="M25.5 19.922v-1.5m-3.94 3.061-1.06-1.06m1.06 9-1.06 1.06m9-9 1.06-1.06m-10.56 5h-1.5m13.864 7.189-2.491 4.627c-.285.529-.427.793-.599.86a.5.5 0 0 1-.45-.044c-.155-.099-.243-.386-.42-.96l-3.459-11.227c-.144-.47-.216-.703-.158-.862a.5.5 0 0 1 .297-.297c.158-.058.393.014.861.159l11.228 3.458c.574.177.86.265.96.42a.5.5 0 0 1 .044.45c-.067.172-.331.314-.86.6l-4.627 2.49a1.04 1.04 0 0 0-.153.091.496.496 0 0 0-.082.083c-.027.034-.048.073-.09.152Z"
      stroke="#034D78"
      strokeWidth={1.67}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={4.5}
      y={4.422}
      width={48}
      height={48}
      rx={24}
      stroke="#FEEABA"
      strokeWidth={8}
    />
  </svg>
)

export default SelectPlayerSvg