export class PositionPlayer{
    name: string;
    label: string;
    avalaibleStatPlayer: StatPlayer[];

    constructor(data: any){
        this.name = data.name;
        this.label = data.label;
        this.avalaibleStatPlayer  = data.avalaibleStatPlayer;
    }
}

export class StatPlayer{
    name: string;
    label: string;
    best: ('min' | 'max');
    constructor(data: any){
        this.name = data.name;
        this.label = data.label;
        this.best = data.best ?? 'max'
    }
}

export function getPositionPlayerList(){
    return [
        new PositionPlayer({
            name: 'gk', 
            label: 'Gardien',  
            avalaibleStatPlayer: [
                new StatPlayer(playerStats[EPlayerStat.TOTAL_MINUTES_ON_FIELD]),
                new StatPlayer(playerStats[EPlayerStat.PASSES_ACCURATE]),
                new StatPlayer(playerStats[EPlayerStat.LONG_PASSES_ACCURATE]),
                new StatPlayer(playerStats[EPlayerStat.DRIBBLES_SUCCESSFUL]),
                new StatPlayer(playerStats[EPlayerStat.DUELS_WON]),
                new StatPlayer(playerStats[EPlayerStat.AIR_DUELS_WON]),
                new StatPlayer(playerStats[EPlayerStat.YELLOW_CARD]),
                new StatPlayer(playerStats[EPlayerStat.RED_CARD]),
                new StatPlayer(playerStats[EPlayerStat.DEFENSIVE_DUEL_WON]),
                new StatPlayer(playerStats[EPlayerStat.SLIDING_SUCESSFUL_TACKLES]),
                new StatPlayer(playerStats[EPlayerStat.CLEARANCES]),
                new StatPlayer(playerStats[EPlayerStat.FAULT]),
                new StatPlayer(playerStats[EPlayerStat.TOUCH_IN_PENALTY_AREA]),
                new StatPlayer(playerStats[EPlayerStat.FAULTS_SUFFERED]),
                new StatPlayer(playerStats[EPlayerStat.PASS_RECEIVED]),
                new StatPlayer(playerStats[EPlayerStat.FOWARD_ACCURATE_PASSES]),
                new StatPlayer(playerStats[EPlayerStat.GOAL_CONCEDED]),
                new StatPlayer(playerStats[EPlayerStat.XCG]),
                new StatPlayer(playerStats[EPlayerStat.SHOTS_AGAINST]),
                new StatPlayer(playerStats[EPlayerStat.STOPS_REFLEXES]),
                new StatPlayer(playerStats[EPlayerStat.OUTPUTS]),
            ]
        }),
        new PositionPlayer({
            name: 'defender',
            label: 'Défenseur',
            avalaibleStatPlayer: [
                new StatPlayer(playerStats[EPlayerStat.TOTAL_MINUTES_ON_FIELD]),
                new StatPlayer(playerStats[EPlayerStat.TOTAL_ACTIONS]),
                new StatPlayer(playerStats[EPlayerStat.TOTAL_GOALS]),
                new StatPlayer(playerStats[EPlayerStat.DECISIVE_PASS]),
                new StatPlayer(playerStats[EPlayerStat.SHOT_ON_TARGET]),
                new StatPlayer(playerStats[EPlayerStat.XG]),
                new StatPlayer(playerStats[EPlayerStat.PASSES_ACCURATE]),
                new StatPlayer(playerStats[EPlayerStat.LONG_PASSES_ACCURATE]),
                new StatPlayer(playerStats[EPlayerStat.DRIBBLES_SUCCESSFUL]),
                new StatPlayer(playerStats[EPlayerStat.DUELS_WON]),
                new StatPlayer(playerStats[EPlayerStat.AIR_DUELS_WON]),
                new StatPlayer(playerStats[EPlayerStat.INTERCEPTIONS]),
                new StatPlayer(playerStats[EPlayerStat.LOSS_OWN_LAND]),
                new StatPlayer(playerStats[EPlayerStat.RECOVERIES_OPPOSITE_GROUNG]),
                new StatPlayer(playerStats[EPlayerStat.YELLOW_CARD]),
                new StatPlayer(playerStats[EPlayerStat.RED_CARD]),
                new StatPlayer(playerStats[EPlayerStat.DEFENSIVE_DUEL_WON]),
                new StatPlayer(playerStats[EPlayerStat.LOST_WON_BALL_DUELS]),
                new StatPlayer(playerStats[EPlayerStat.SLIDING_SUCESSFUL_TACKLES]),
                new StatPlayer(playerStats[EPlayerStat.CLEARANCES]),
                new StatPlayer(playerStats[EPlayerStat.FAULT]),
                new StatPlayer(playerStats[EPlayerStat.ASSIST_WITH_A_SHOT]),
                new StatPlayer(playerStats[EPlayerStat.TOUCH_IN_PENALTY_AREA]),
                new StatPlayer(playerStats[EPlayerStat.PROGRESSIVE_RACE]),
                new StatPlayer(playerStats[EPlayerStat.FAULTS_SUFFERED]),
                new StatPlayer(playerStats[EPlayerStat.DEEP_ACCURATE_PASSES]),
                new StatPlayer(playerStats[EPlayerStat.PASS_THIRD_ACCURATE]),
                new StatPlayer(playerStats[EPlayerStat.PASS_TO_PENALTY_AREA_ACCURATE]),
                new StatPlayer(playerStats[EPlayerStat.PASS_RECEIVED]),
                new StatPlayer(playerStats[EPlayerStat.FOWARD_ACCURATE_PASSES]),
                new StatPlayer(playerStats[EPlayerStat.REAR_PASSES_ACCURATE]),
                new StatPlayer(playerStats[EPlayerStat.GOAL_CONCEDED]),
                new StatPlayer(playerStats[EPlayerStat.SHOTS_AGAINST]),
                new StatPlayer(playerStats[EPlayerStat.PASS_TO_GK_ACCURATE]),
            ]
        }),
        new PositionPlayer({
            name: "defensive midfielder",
            label: 'Milieu défensive',
            avalaibleStatPlayer: [
                new StatPlayer(playerStats[EPlayerStat.TOTAL_MINUTES_ON_FIELD]),
                new StatPlayer(playerStats[EPlayerStat.TOTAL_ACTIONS]),
                new StatPlayer(playerStats[EPlayerStat.TOTAL_GOALS]),
                new StatPlayer(playerStats[EPlayerStat.DECISIVE_PASS]),
                new StatPlayer(playerStats[EPlayerStat.SHOT_ON_TARGET]),
                new StatPlayer(playerStats[EPlayerStat.XG]),
                new StatPlayer(playerStats[EPlayerStat.PASSES_ACCURATE]),
                new StatPlayer(playerStats[EPlayerStat.LONG_PASSES_ACCURATE]),
                new StatPlayer(playerStats[EPlayerStat.DRIBBLES_SUCCESSFUL]),
                new StatPlayer(playerStats[EPlayerStat.DUELS_WON]),
                new StatPlayer(playerStats[EPlayerStat.AIR_DUELS_WON]),
                new StatPlayer(playerStats[EPlayerStat.INTERCEPTIONS]),
                new StatPlayer(playerStats[EPlayerStat.LOSS_OWN_LAND]),
                new StatPlayer(playerStats[EPlayerStat.RECOVERIES_OPPOSITE_GROUNG]),
                new StatPlayer(playerStats[EPlayerStat.YELLOW_CARD]),
                new StatPlayer(playerStats[EPlayerStat.RED_CARD]),
                new StatPlayer(playerStats[EPlayerStat.DEFENSIVE_DUEL_WON]),
                new StatPlayer(playerStats[EPlayerStat.LOST_WON_BALL_DUELS]),
                new StatPlayer(playerStats[EPlayerStat.SLIDING_SUCESSFUL_TACKLES]),
                new StatPlayer(playerStats[EPlayerStat.CLEARANCES]),
                new StatPlayer(playerStats[EPlayerStat.FAULT]),
                new StatPlayer(playerStats[EPlayerStat.ASSIST_WITH_A_SHOT]),
                new StatPlayer(playerStats[EPlayerStat.TOUCH_IN_PENALTY_AREA]),
                new StatPlayer(playerStats[EPlayerStat.PROGRESSIVE_RACE]),
                new StatPlayer(playerStats[EPlayerStat.FAULTS_SUFFERED]),
                new StatPlayer(playerStats[EPlayerStat.DEEP_ACCURATE_PASSES]),
                new StatPlayer(playerStats[EPlayerStat.PASS_THIRD_ACCURATE]),
                new StatPlayer(playerStats[EPlayerStat.PASS_RECEIVED]),
                new StatPlayer(playerStats[EPlayerStat.FOWARD_ACCURATE_PASSES]),
                new StatPlayer(playerStats[EPlayerStat.REAR_PASSES_ACCURATE]),
                new StatPlayer(playerStats[EPlayerStat.SHOTS_AGAINST]),
                new StatPlayer(playerStats[EPlayerStat.PASS_TO_GK_ACCURATE]),
            ]
        }),
        {
            name: "midfielder",
            label: 'Milieu',
            avalaibleStatPlayer: [
                new StatPlayer(playerStats[EPlayerStat.TOTAL_MINUTES_ON_FIELD]),
                new StatPlayer(playerStats[EPlayerStat.TOTAL_ACTIONS]),
                new StatPlayer(playerStats[EPlayerStat.TOTAL_GOALS]),
                new StatPlayer(playerStats[EPlayerStat.DECISIVE_PASS]),
                new StatPlayer(playerStats[EPlayerStat.SHOT_ON_TARGET]),
                new StatPlayer(playerStats[EPlayerStat.XG]),
                new StatPlayer(playerStats[EPlayerStat.PASSES_ACCURATE]),
                new StatPlayer(playerStats[EPlayerStat.LONG_PASSES_ACCURATE]),
                new StatPlayer(playerStats[EPlayerStat.DRIBBLES_SUCCESSFUL]),
                new StatPlayer(playerStats[EPlayerStat.DUELS_WON]),
                new StatPlayer(playerStats[EPlayerStat.AIR_DUELS_WON]),
                new StatPlayer(playerStats[EPlayerStat.INTERCEPTIONS]),
                new StatPlayer(playerStats[EPlayerStat.LOSS_OWN_LAND]),
                new StatPlayer(playerStats[EPlayerStat.RECOVERIES_OPPOSITE_GROUNG]),
                new StatPlayer(playerStats[EPlayerStat.YELLOW_CARD]),
                new StatPlayer(playerStats[EPlayerStat.RED_CARD]),
                new StatPlayer(playerStats[EPlayerStat.DEFENSIVE_DUEL_WON]),
                new StatPlayer(playerStats[EPlayerStat.LOST_WON_BALL_DUELS]),
                new StatPlayer(playerStats[EPlayerStat.SLIDING_SUCESSFUL_TACKLES]),
                new StatPlayer(playerStats[EPlayerStat.CLEARANCES]),
                new StatPlayer(playerStats[EPlayerStat.FAULT]),
                new StatPlayer(playerStats[EPlayerStat.ASSIST_WITH_A_SHOT]),
                new StatPlayer(playerStats[EPlayerStat.PROGRESSIVE_RACE]),
                new StatPlayer(playerStats[EPlayerStat.FAULTS_SUFFERED]),
                new StatPlayer(playerStats[EPlayerStat.DEEP_ACCURATE_PASSES]),
                new StatPlayer(playerStats[EPlayerStat.PASS_THIRD_ACCURATE]),
                new StatPlayer(playerStats[EPlayerStat.PASS_TO_PENALTY_AREA_ACCURATE]),
                new StatPlayer(playerStats[EPlayerStat.PASS_RECEIVED]),
                new StatPlayer(playerStats[EPlayerStat.FOWARD_ACCURATE_PASSES]),
                new StatPlayer(playerStats[EPlayerStat.REAR_PASSES_ACCURATE]),
                new StatPlayer(playerStats[EPlayerStat.SHOTS_AGAINST]),
                new StatPlayer(playerStats[EPlayerStat.GOAL_FROM_FREE_KICK]),
                new StatPlayer(playerStats[EPlayerStat.GOAL_FROM_SHORT_FREE_KICK]),
                new StatPlayer(playerStats[EPlayerStat.GOAL_FROM_LONG_FREE_KICK]),
            ]
        },
        {
            name: "offensive_midfielder",
            label: 'Milieu offensive',
            avalaibleStatPlayer:[
                new StatPlayer(playerStats[EPlayerStat.TOTAL_MINUTES_ON_FIELD]),
                new StatPlayer(playerStats[EPlayerStat.TOTAL_ACTIONS]),
                new StatPlayer(playerStats[EPlayerStat.TOTAL_GOALS]),
                new StatPlayer(playerStats[EPlayerStat.DECISIVE_PASS]),
                new StatPlayer(playerStats[EPlayerStat.SHOT_ON_TARGET]),
                new StatPlayer(playerStats[EPlayerStat.XG]),
                new StatPlayer(playerStats[EPlayerStat.PASSES_ACCURATE]),
                new StatPlayer(playerStats[EPlayerStat.LONG_PASSES_ACCURATE]),
                new StatPlayer(playerStats[EPlayerStat.CENTRES_ACCURATE]),
                new StatPlayer(playerStats[EPlayerStat.DRIBBLES_SUCCESSFUL]),
                new StatPlayer(playerStats[EPlayerStat.DUELS_WON]),
                new StatPlayer(playerStats[EPlayerStat.AIR_DUELS_WON]),
                new StatPlayer(playerStats[EPlayerStat.INTERCEPTIONS]),
                new StatPlayer(playerStats[EPlayerStat.LOSS_OWN_LAND]),
                new StatPlayer(playerStats[EPlayerStat.RECOVERIES_OPPOSITE_GROUNG]),
                new StatPlayer(playerStats[EPlayerStat.YELLOW_CARD]),
                new StatPlayer(playerStats[EPlayerStat.RED_CARD]),
                new StatPlayer(playerStats[EPlayerStat.DEFENSIVE_DUEL_WON]),
                new StatPlayer(playerStats[EPlayerStat.LOST_WON_BALL_DUELS]),
                new StatPlayer(playerStats[EPlayerStat.FAULT]),
                new StatPlayer(playerStats[EPlayerStat.ASSIST_WITH_A_SHOT]),
                new StatPlayer(playerStats[EPlayerStat.OFFENSIVE_WINNING_DUELS]),
                new StatPlayer(playerStats[EPlayerStat.OFFSIDE]),
                new StatPlayer(playerStats[EPlayerStat.PROGRESSIVE_RACE]),
                new StatPlayer(playerStats[EPlayerStat.FAULTS_SUFFERED]),
                new StatPlayer(playerStats[EPlayerStat.DEEP_ACCURATE_PASSES]),
                new StatPlayer(playerStats[EPlayerStat.XA]),
                new StatPlayer(playerStats[EPlayerStat.SECOND_ASSISTS]),
                new StatPlayer(playerStats[EPlayerStat.PASS_THIRD_ACCURATE]),
                new StatPlayer(playerStats[EPlayerStat.PASS_TO_PENALTY_AREA_ACCURATE]),
                new StatPlayer(playerStats[EPlayerStat.PASS_RECEIVED]),
                new StatPlayer(playerStats[EPlayerStat.FOWARD_ACCURATE_PASSES]),
                new StatPlayer(playerStats[EPlayerStat.REAR_PASSES_ACCURATE]),
                new StatPlayer(playerStats[EPlayerStat.SHOTS_AGAINST]),
                new StatPlayer(playerStats[EPlayerStat.GOAL_FROM_FREE_KICK]),
                new StatPlayer(playerStats[EPlayerStat.GOAL_FROM_SHORT_FREE_KICK]),
                new StatPlayer(playerStats[EPlayerStat.GOAL_FROM_LONG_FREE_KICK]),
            ]
        },
        {
            name: "attacker",
            label: 'Attaquant',
            avalaibleStatPlayer: [
                new StatPlayer(playerStats[EPlayerStat.TOTAL_MINUTES_ON_FIELD]),
                new StatPlayer(playerStats[EPlayerStat.TOTAL_ACTIONS]),
                new StatPlayer(playerStats[EPlayerStat.TOTAL_GOALS]),
                new StatPlayer(playerStats[EPlayerStat.DECISIVE_PASS]),
                new StatPlayer(playerStats[EPlayerStat.SHOT_ON_TARGET]),
                new StatPlayer(playerStats[EPlayerStat.XG]),
                new StatPlayer(playerStats[EPlayerStat.PASSES_ACCURATE]),
                new StatPlayer(playerStats[EPlayerStat.LONG_PASSES_ACCURATE]),
                new StatPlayer(playerStats[EPlayerStat.CENTRES_ACCURATE]),
                new StatPlayer(playerStats[EPlayerStat.DRIBBLES_SUCCESSFUL]),
                new StatPlayer(playerStats[EPlayerStat.DUELS_WON]),
                new StatPlayer(playerStats[EPlayerStat.AIR_DUELS_WON]),
                new StatPlayer(playerStats[EPlayerStat.INTERCEPTIONS]),
                new StatPlayer(playerStats[EPlayerStat.RECOVERIES_OPPOSITE_GROUNG]),
                new StatPlayer(playerStats[EPlayerStat.YELLOW_CARD]),
                new StatPlayer(playerStats[EPlayerStat.RED_CARD]),
                new StatPlayer(playerStats[EPlayerStat.LOST_WON_BALL_DUELS]),
                new StatPlayer(playerStats[EPlayerStat.FAULT]),
                new StatPlayer(playerStats[EPlayerStat.ASSIST_WITH_A_SHOT]),
                new StatPlayer(playerStats[EPlayerStat.OFFENSIVE_WINNING_DUELS]),
                new StatPlayer(playerStats[EPlayerStat.OFFSIDE]),
                new StatPlayer(playerStats[EPlayerStat.PROGRESSIVE_RACE]),
                new StatPlayer(playerStats[EPlayerStat.FAULTS_SUFFERED]),
                new StatPlayer(playerStats[EPlayerStat.DEEP_ACCURATE_PASSES]),
                new StatPlayer(playerStats[EPlayerStat.XA]),
                new StatPlayer(playerStats[EPlayerStat.SECOND_ASSISTS]),
                new StatPlayer(playerStats[EPlayerStat.PASS_THIRD_ACCURATE]),
                new StatPlayer(playerStats[EPlayerStat.PASS_TO_PENALTY_AREA_ACCURATE]),
                new StatPlayer(playerStats[EPlayerStat.PASS_RECEIVED]),
                new StatPlayer(playerStats[EPlayerStat.FOWARD_ACCURATE_PASSES]),
                new StatPlayer(playerStats[EPlayerStat.REAR_PASSES_ACCURATE]),
                new StatPlayer(playerStats[EPlayerStat.SHOTS_AGAINST]),
                new StatPlayer(playerStats[EPlayerStat.GOAL_FROM_FREE_KICK]),
                new StatPlayer(playerStats[EPlayerStat.GOAL_FROM_SHORT_FREE_KICK]),
                new StatPlayer(playerStats[EPlayerStat.GOAL_FROM_LONG_FREE_KICK]),
            ]
        }
    ]
}

enum EPlayerStat{
    DECISIVE_PASS=3,
    SHOT_ON_TARGET=4,
    XG=5,
    PASSES_ACCURATE=6,
    LONG_PASSES_ACCURATE=7,
    CENTRES_ACCURATE=8,
    DRIBBLES_SUCCESSFUL=9,
    DUELS_WON=10,
    AIR_DUELS_WON=11,
    INTERCEPTIONS=12,
    LOSS_OWN_LAND=13,
    RECOVERIES_OPPOSITE_GROUNG=14,
    YELLOW_CARD=15,
    RED_CARD=16,
    DEFENSIVE_DUEL_WON=17,
    LOST_WON_BALL_DUELS=18,
    SLIDING_SUCESSFUL_TACKLES=19,
    CLEARANCES=20,
    FAULT=21,
    ASSIST_WITH_A_SHOT=22,
    OFFENSIVE_WINNING_DUELS=23,
    TOUCH_IN_PENALTY_AREA=24,
    OFFSIDE=25,
    PROGRESSIVE_RACE=26,
    FAULTS_SUFFERED=27,
    DEEP_ACCURATE_PASSES=28,
    XA=29,
    SECOND_ASSISTS=30,
    PASS_THIRD_ACCURATE=31,
    PASS_TO_PENALTY_AREA_ACCURATE=32,
    PASS_RECEIVED=33,
    FOWARD_ACCURATE_PASSES=34,
    REAR_PASSES_ACCURATE=35,
    GOAL_CONCEDED=36,
    XCG=37,
    SHOTS_AGAINST=38,
    STOPS_REFLEXES=39,
    OUTPUTS=40,
    PASS_TO_GK_ACCURATE=41,
    GOAL_FROM_FREE_KICK=42,
    GOAL_FROM_SHORT_FREE_KICK=43,
    GOAL_FROM_LONG_FREE_KICK=44,

    // API PROPS
    AVERAGE_ACCELERATIONS = 0,
    AVERAGE_AERIAL_DUELS = 1,
    AVERAGE_ASSISTS = 2,
    AVERAGE_ATTACKING_ACTIONS = 3,
    AVERAGE_BACKP_PASSES = 4,
    AVERAGE_BALL_LOSSES = 5,
    AVERAGE_BALL_RECOVERIES = 6,
    AVERAGE_CLEANRANCES = 7,
    AVERAGE_CORNERS = 8,
    AVERAGE_COUNTERPRESSING_RECOVERIES = 9,
    AVERAGE_CROSSES = 10,
    AVERAGE_DANGEROUS_OPPONENT_HALF_RECOVERIES = 11,
    AVERAGE_DANGEROUS_OWN_HALF_LOSSES = 12,
    AVERAGE_DEFENSIVE_ACTIONS = 13,
    AVERAGE_DEFENSIVE_DUELS = 14,
    AVERAGE_DEFENSIVE_DUELS_WON = 15,
    AVERAGE_DIRECT_FREE_KICKS = 16,
    AVERAGE_DIRECT_FREE_KICKS_ON_TARGET = 17,
    AVERAGE_DIRECT_RED_CARDS = 18,
    AVERAGE_DRIBBLE_DISTANCE_FROM_OPPONENT_GOAL = 19,
    AVERAGE_DRIBBLES = 20,
    AVERAGE_DRIBBLES_AGAINST = 21,
    AVERAGE_DRIBBLES_AGAINST_WON = 22,
    AVERAGE_DUELS = 23,
    AVERAGE_DUELS_WON = 24,
    AVERAGE_FIELD_AERIAL_DUELS = 25,
    AVERAGE_FIELD_AERIAL_DUELS_WON = 26,
    AVERAGE_FORWARD_PASSES = 27,
    AVERAGE_FOULS = 28,
    AVERAGE_FOULS_SUFFERED = 29,
    AVERAGE_FREE_KICKS = 30,
    AVERAGE_FREE_KICKS_ON_TARGET = 31,
    AVERAGE_GK_AERIAL_DUELS = 32,
    AVERAGE_GK_AERIAL_DUELS_WON = 33,
    AVERAGE_GK_CONCEDED_COALS = 34,
    AVERAGE_GK_EXITS = 35,
    AVERAGE_GK_SAVES = 36,
    AVERAGE_GK_SHOTS_AGAINST = 37,
    AVERAGE_GK_SUCCESSFUL_EXITS = 38,
    AVERAGE_GOAL_KICKS = 39,
    AVERAGE_GOAL_KICKS_LONG = 40,
    AVERAGE_GOAL_KICKS_SHORT = 41,
    AVERAGE_GOALS = 42,
    AVERAGE_HEAD_SHOTS = 43,
    AVERAGE_INTERCEPTIONS = 44,
    AVERAGE_KEY_PASSES = 45,
    AVERAGE_LATERAL_PASSES = 46,
    AVERAGE_LINKUP_PLAYS = 47,
    AVERAGE_LONG_PASS_LENGTH = 48,
    AVERAGE_LONG_PASSES = 49,
    AVERAGE_LOOSE_BALL_DUELS = 50,
    AVERAGE_LOOSE_BALL_DUELS_WON = 51,
    AVERAGE_LOSSES = 52,
    AVERAGE_MISSED_BALLS = 53,
    AVERAGE_NEW_DEFENSIVE_DUELS_WON = 54,
    AVERAGE_NEW_DUELS_WON = 55,
    AVERAGE_NEW_OFFENSIVE_DUELS_WON = 56,
    AVERAGE_NEW_SUCCESSFUL_DRIBBLES = 57,
    AVERAGE_OFFENSIVE_DUELS = 58,
    AVERAGE_OFFENSIVE_DUELS_WON = 59,
    AVERAGE_OFFSIDES = 60,
    AVERAGE_OPPONENT_HALF_RECOVERIES = 61,
    AVERAGE_OWN_HALF_LOSSES = 62,
    AVERAGE_PASS_LENGTH = 63,
    AVERAGE_PASSES = 64,
    AVERAGE_PASSES_TO_FINAL_THIRD = 65,
    AVERAGE_PENALTIES = 66,
    AVERAGE_PROGRESSIVE_PASSES = 67,
    AVERAGE_PROGRESSIVE_RUN = 68,
    AVERAGE_RECEIVED_PASS = 69,
    AVERAGE_RED_CARDS = 70,
    AVERAGE_SECOND_ASSISTS = 71,
    AVERAGE_SHOT_ASSISTS = 72,
    AVERAGE_SHOT_ON_TARGET_ASSISTS = 73,
    AVERAGE_SHOTS = 74,
    AVERAGE_SHOTS_BLOCKED = 75,
    AVERAGE_SHOTS_ON_TARGET = 76,
    AVERAGE_SLIDING_TACKLES = 77,
    AVERAGE_SMART_PASSES = 78,
    AVERAGE_SUCCESSFUL_ATTACKING_ACTIONS = 79,
    AVERAGE_SUCCESSFUL_BACK_PASSES = 80,
    AVERAGE_SUCCESSFUL_CROSSES = 81,
    AVERAGE_SUCCESSFUL_DEFENSIVE_ACTION = 82,
    AVERAGE_SUCCESSFUL_DRIBBLES = 83,
    AVERAGE_SUCCESSFUL_FORWARD_PASSES = 84,
    AVERAGE_SUCCESSFUL_GOAL_KICKS = 85,
    AVERAGE_SUCCESSFUL_KEY_PASSES = 86,
    AVERAGE_SUCCESSFUL_LATERAL_PASSES = 87,
    AVERAGE_SUCCESSFUL_LINKUP_PLAYS = 88,
    AVERAGE_SUCCESSFUL_LONG_PASSES = 89,
    AVERAGE_SUCCESSFUL_PASSES = 90,
    AVERAGE_SUCCESSFUL_PASSES_TO_FINAL_THIRD = 91,
    AVERAGE_SUCCESSFUL_PENALTIES = 92,
    AVERAGE_SUCCESSFUL_PROGRESSIVE_PASSES = 93,
    AVERAGE_SUCCESSFUL_SLIDING_TACKLES = 94,
    AVERAGE_SUCCESSFUL_SMART_PASSES = 95,
    AVERAGE_SUCCESSFUL_THROUGH_PASSES = 96,
    AVERAGE_SUCCESSFUL_VERTICAL_PASSES = 97,
    AVERAGE_THIRD_ASSISTS = 98,
    AVERAGE_THROUGH_PASSES = 99,
    AVERAGE_TOUCH_IN_BOX = 100,
    AVERAGE_VERTICAL_PASSES = 101,
    AVERAGE_XG_ASSIST = 102,
    AVERAGE_XG_SAVE = 103,
    AVERAGE_XG_SHOT = 104,
    AVERAGE_YELLOW_CARDS = 105,
    PERCENT_AERIAL_DUELS_WON = 106,
    PERCENT_DEFENSIVE_DUELS_WON = 107,
    PERCENT_DIRECT_FREE_KICKS_ON_TARGET = 108,
    PERCENT_DRIBBLES_AGAINST_WON = 109,
    PERCENT_DUELS_WON = 110,
    PERCENT_FIELD_AERIAL_DUELS_WON = 111,
    PERCENT_GK_AERIAL_DUELS_WON = 112,
    PERCENT_GK_SAVES = 113,
    PERCENT_GK_SUCCESSFUL_EXITS = 114,
    PERCENT_GOAL_CONVERSION = 115,
    PERCENT_HEAD_SHOTS_ON_TARGET = 116,
    PERCENT_NEW_DEFENSIVE_DUELS_WON = 117,
    PERCENT_NEW_DUELS_WON = 118,
    PERCENT_NEW_OFFENSIVE_DUELS_WON = 119,
    PERCENT_NEW_SUCCESSFUL_DRIBBLES = 120,
    PERCENT_OFFENSIVE_DUELS_WON = 121,
    PERCENT_PENALTIES_CONVERSION = 122,
    PERCENT_SHOTS_ON_TARGET = 123,
    PERCENT_SUCCESSFUL_BACK_PASSES = 124,
    PERCENT_SUCCESSFUL_CROSSES = 125,
    PERCENT_SUCCESSFUL_DRIBBLES = 126,
    PERCENT_SUCCESSFUL_FORWARD_PASSES = 127,
    PERCENT_SUCCESSFUL_GOAL_KICKS = 128,
    PERCENT_SUCCESSFUL_KEY_PASSES = 129,
    PERCENT_SUCCESSFUL_LATERAL_PASSES = 130,
    PERCENT_SUCCESSFUL_LINKUP_PLAYS = 131,
    PERCENT_SUCCESSFUL_LONG_PASSES = 132,
    PERCENT_SUCCESSFUL_PASSES = 133,
    PERCENT_SUCCESSFUL_PASSES_TO_FINAL_THIRD = 134,
    PERCENT_SUCCESSFUL_PROGRESSIVE_PASSES = 135,
    PERCENT_SUCCESSFUL_SHOT_ASSISTS = 136,
    PERCENT_SUCCESSFUL_SLIDING_TACKLES = 137,
    PERCENT_SUCCESSFUL_SMART_PASSES = 138,
    PERCENT_SUCCESSFUL_THROUGH_PASSES = 139,
    PERCENT_SUCCESSFUL_VERTICAL_PASSES = 140,
    PERCENT_WIN = 141,
    PERCENT_YELLOW_CARDS_PER_FOUL = 142,
    TOTAL_ACCELERATIONS = 143,
    TOTAL_AERIAL_DUELS = 144,
    TOTAL_AERIAL_DUELS_WON = 145,
    TOTAL_ASSISTS = 146,
    TOTAL_ATTACKING_ACTIONS = 147,
    TOTAL_BACK_PASSES = 148,
    TOTAL_CLEARANCES = 149,
    TOTAL_CORNERS = 150,
    TOTAL_COUNTERPRESSING_RECOVERIES = 151,
    TOTAL_CROSSES = 152,
    TOTAL_DANGEROUS_OPPONENT_HALF_RECOVERIES = 153,
    TOTAL_DANGEROUS_OWN_HALF_LOSSES = 154,
    TOTAL_DEFENSIVE_ACTIONS = 155,
    TOTAL_DEFENSIVE_DUELS = 156,
    TOTAL_DEFENSIVE_DUELS_WON = 157,
    TOTAL_DIRECT_FREE_KICKS = 158,
    TOTAL_DIRECT_FREE_KICKS_ON_TARGET = 159,
    TOTAL_DIRECT_RED_CARDS = 160,
    TOTAL_DRIBBLES = 161,
    TOTAL_DRIBBLES_AGAINST = 162,
    TOTAL_DRIBBLES_AGAINST_WON = 163,
    TOTAL_DUELS = 164,
    TOTAL_DUELS_WON = 165,
    TOTAL_FIELD_AERIAL_DUELS = 166,
    TOTAL_FIELD_AERIAL_DUELS_WON = 167,
    TOTAL_FORWARD_PASSES = 168,
    TOTAL_FOULS = 169,
    TOTAL_FOULS_SUFFERED = 170,
    TOTAL_FREE_KICKS = 171,
    TOTAL_FREE_KICKS_ON_TARGET = 172,
    TOTAL_GK_AERIAL_DUELS = 173,
    TOTAL_GK_AERIAL_DUELS_WON = 174,
    TOTAL_GK_CLEAN_SHEETS = 175,
    TOTAL_GK_CONCEDED_GOALS = 176,
    TOTAL_GK_EXITS = 177,
    TOTAL_GK_SAVES = 178,
    TOTAL_GK_SHOTS_AGAINST = 179,
    TOTAL_GK_SUCCESSFUL_EXITS = 180,
    TOTAL_GOAL_KICKS = 181,
    TOTAL_GOAL_KICKS_LONG = 182,
    TOTAL_GOAL_KICKS_SHORT = 183,
    TOTAL_GOALS = 184,
    TOTAL_HEAD_SHOTS = 185,
    TOTAL_INTERCEPTIONS = 186,
    TOTAL_KEY_PASSES = 187,
    TOTAL_LATERAL_PASSES = 188,
    TOTAL_LINKUP_PLAYS = 189,
    TOTAL_LONG_PASSES = 190,
    TOTAL_LOOSE_BALL_DUELS = 191,
    TOTAL_LOOSE_BALL_DUELS_WON = 192,
    TOTAL_LOSSES = 193,
    TOTAL_MATCHES = 194,
    TOTAL_MATCHES_COMING_OFF = 195,
    TOTAL_MATCHES_IN_START = 196,
    TOTAL_MATCHES_SUBSTITUTED = 197,
    TOTAL_MINUTES_ON_FIELD = 198,
    TOTAL_MINUTES_TAGGED = 199,
    TOTAL_MISSED_BALLS = 200,
    TOTAL_NEW_DEFENSIVE_DUELS_WON = 201,
    TOTAL_NEW_DUELS_WON = 202,
    TOTAL_NEW_OFFENSIVE_DUELS_WON = 203,
    TOTAL_NEW_SUCCESSFUL_DRIBBLES = 204,
    TOTAL_OFFENSIVE_DUELS = 205,
    TOTAL_OFFENSIVE_DUELS_WON = 206,
    TOTAL_OFFSIDES = 207,
    TOTAL_OPPONENT_HALF_RECOVERIES = 208,
    TOTAL_OWN_HALF_LOSSES = 209,
    TOTAL_PASSES = 210,
    TOTAL_PASSES_TO_FINAL_THIRD = 211,
    TOTAL_PENALTIES = 212,
    TOTAL_PRESSING_DUELS = 213,
    TOTAL_PRESSING_DUELS_WON = 214,
    TOTAL_PROGRESSIVE_PASSES = 215,
    TOTAL_PROGRESSIVE_RUN = 216,
    TOTAL_RECEIVED_PASS = 217,
    TOTAL_RECOVERIES = 218,
    TOTAL_RED_CARDS = 219,
    TOTAL_SECOND_ASSISTS = 220,
    TOTAL_SHOT_ASSISTS = 221,
    TOTAL_SHOT_ON_TARGET_ASSISTS = 222,
    TOTAL_SHOTS = 223,
    TOTAL_SHOTS_BLOCKED = 224,
    TOTAL_SHOTS_ON_TARGET = 225,
    TOTAL_SLIDING_TACKLES = 226,
    TOTAL_SMART_PASSES = 227,
    TOTAL_SUCCESSFUL_ATTACKING_ACTIONS = 228,
    TOTAL_SUCCESSFUL_BACK_PASSES = 229,
    TOTAL_SUCCESSFUL_CROSSES = 230,
    TOTAL_SUCCESSFUL_DEFENSIVE_ACTION = 231,
    TOTAL_SUCCESSFUL_DRIBBLES = 232,
    TOTAL_SUCCESSFUL_FORWARD_PASSES = 233,
    TOTAL_SUCCESSFUL_GOAL_KICKS = 234,
    TOTAL_SUCCESSFUL_KEY_PASSES = 235,
    TOTAL_SUCCESSFUL_LATERAL_PASSES = 236,
    TOTAL_SUCCESSFUL_LINKUP_PLAYS = 237,
    TOTAL_SUCCESSFUL_LONG_PASSES = 238,
    TOTAL_SUCCESSFUL_PASSES = 239,
    TOTAL_SUCCESSFUL_PASSES_TO_FINAL_THIRD = 240,
    TOTAL_SUCCESSFUL_PENALTIES = 241,
    TOTAL_SUCCESSFUL_PROGRESSIVE_PASSES = 242,
    TOTAL_SUCCESSFUL_SLIDING_TACKLES = 243,
    TOTAL_SUCCESSFUL_SMART_PASSES = 244,
    TOTAL_SUCCESSFUL_THROUGH_PASSES = 245,
    TOTAL_SUCCESSFUL_VERTICAL_PASSES = 246,
    TOTAL_THIRD_ASSISTS = 247,
    TOTAL_THROUGH_PASSES = 248,
    TOTAL_TOUCH_IN_BOX = 249,
    TOTAL_VERTICAL_PASSES = 250,
    TOTAL_XG_ASSIST = 251,
    TOTAL_XG_SAVE = 252,
    TOTAL_XG_SHOT = 253,
    TOTAL_YELLOW_CARDS = 254,
    TOTAL_ACTIONS = 255,
    TOTAL_SUCCESSFUL_ACTIONS = 256,
}
const playerStats = [
    // {name: 'decisive_pass', label: "Passe décisive"},
    // {name: 'shot_on_target', label: "Tirs / cadrés"},
    // {name: 'xg', label: "XG"},
    // {name: 'passes_accurate', label: "Passes / précises"},
    // {name: 'long_passes_accurate', label: "Passes longues / précises"},
    // {name: 'centres_accurate', label: "Centres / précis"},
    // {name: 'dribbles_successful', label: "Dribbles / réussis"},
    // {name: 'duels_won', label: "Duels / gagnés"},
    // {name: 'air_duels_won', label: "Duels aériens / gagnés"},
    // {name: 'interceptions', label: "Interceptions"},
    // {name: 'loss_own_land', label: "Pertes/propre terrain"},
    // {name: 'recoveries_opposite_groung', label: "Récupérations/terrain adverse"},
    // {name: 'yellow_card', label: "Carton jaune"},
    // {name: 'red_card', label: "Carton rouge"},
    // {name: 'defensive_duel_won', label: "Duels défensifs / gagnés"},
    // {name: 'lost_won_ball_duels', label: "Duels ballons perdus / gagnés"},
    // {name: 'sliding_sucessful_tackles', label: "Tacles glissés/réussis"},
    // {name: 'clearances', label: "Dégagements"},
    // {name: 'fault', label: "Faute"},
    // {name: 'assist_with_a_shot', label: "Passes décisives avec tir"},
    // {name: 'offensive_winning_duels', label: "Duels offensifs / gagnés"},
    // {name: 'touch_in_penalty_area', label: "Touches de balle dans la surface de réparation"},
    // {name: 'offside', label: "Hors-jeu"},
    // {name: 'progressive_race', label: "Courses progressives"},
    // {name: 'faults_suffered', label: "Fautes subies"},
    // {name: 'deep_accurate_passes', label: "Passes en profondeur / précises"},
    // {name: 'xa', label: "xA"},
    // {name: 'second_assists', label: "Secondes passes décisives"},
    // {name: 'pass_third_accurate', label: "Passes dans 3ème tiers / précises"},
    // {name: 'pass_to_penalty_area_accurate', label: "Passes vers la la surface de réparation/ précises"},
    // {name: 'pass_received', label: "Passes réceptionnées"},
    // {name: 'foward_accurate_passes', label: "Passes en avant / précises"},
    // {name: 'rear_passes_accurate', label: "Passes arrière / précises"},
    // {name: 'goal_conceded', label: "Buts concédés"},
    // {name: 'xcg', label: "xCG"},
    // {name: 'shots_against', label: "Tirs contre"},
    // {name: 'stops_reflexes', label: "Arrêts/avec réflexes"},
    // {name: 'outputs', label: "Sorties"},
    // {name: 'pass_to_gk_accurate', label: "Passes au gardien de but/précises"},
    // {name: 'goal_from_free_kick', label: "But sur coup franc"},
    // {name: 'goal_from_short_free_kick', label: "But sur coup franc court"},
    // {name: 'goal_from_long_free_kick', label: "But sur coup franc long"},


// API PROPS NAME NEED CORRESPONDANCE
    { name: 'average_accelerations', label: ''},
    { name: 'average_aerialDuels', label: ''},
    { name: 'average_assists', label: ''},
    { name: 'average_attackingActions', label: ''},
    { name: 'average_backPasses', label: ''},
    { name: 'average_ballLosses', label: ''},
    { name: 'average_ballRecoveries', label: ''},
    { name: 'average_clearances', label: ''},
    { name: 'average_corners', label: ''},
    { name: 'average_counterpressingRecoveries', label: ''},
    { name: 'average_crosses', label: ''},
    { name: 'average_dangerousOpponentHalfRecoveries', label: ''},
    { name: 'average_dangerousOwnHalfLosses', label: ''},
    { name: 'average_defensiveActions', label: ''},
    { name: 'average_defensiveDuels', label: ''},
    { name: 'average_defensiveDuelsWon', label: ''},
    { name: 'average_directFreeKicks', label: ''},
    { name: 'average_directFreeKicksOnTarget', label: ''},
    { name: 'average_directRedCards', label: ''},
    { name: 'average_dribbleDistanceFromOpponentGoal', label: ''},
    { name: 'average_dribbles', label: ''},
    { name: 'average_dribblesAgainst', label: ''},
    { name: 'average_dribblesAgainstWon', label: ''},
    { name: 'average_duels', label: ''},
    { name: 'average_duelsWon', label: ''},
    { name: 'average_fieldAerialDuels', label: ''},
    { name: 'average_fieldAerialDuelsWon', label: ''},
    { name: 'average_forwardPasses', label: ''},
    { name: 'average_fouls', label: ''},
    { name: 'average_foulsSuffered', label: ''},
    { name: 'average_freeKicks', label: ''},
    { name: 'average_freeKicksOnTarget', label: ''},
    { name: 'average_gkAerialDuels', label: ''},
    { name: 'average_gkAerialDuelsWon', label: ''},
    { name: 'average_gkConcededGoals', label: ''},
    { name: 'average_gkExits', label: ''},
    { name: 'average_gkSaves', label: ''},
    { name: 'average_gkShotsAgainst', label: ''},
    { name: 'average_gkSuccessfulExits', label: ''},
    { name: 'average_goalKicks', label: ''},
    { name: 'average_goalKicksLong', label: ''},
    { name: 'average_goalKicksShort', label: ''},
    { name: 'average_goals', label: ''},
    { name: 'average_headShots', label: ''},
    { name: 'average_interceptions', label: ''},
    { name: 'average_keyPasses', label: ''},
    { name: 'average_lateralPasses', label: ''},
    { name: 'average_linkupPlays', label: ''},
    { name: 'average_longPassLength', label: ''},
    { name: 'average_longPasses', label: ''},
    { name: 'average_looseBallDuels', label: ''},
    { name: 'average_looseBallDuelsWon', label: ''},
    { name: 'average_losses', label: ''},
    { name: 'average_missedBalls', label: ''},
    { name: 'average_newDefensiveDuelsWon', label: ''},
    { name: 'average_newDuelsWon', label: ''},
    { name: 'average_newOffensiveDuelsWon', label: ''},
    { name: 'average_newSuccessfulDribbles', label: ''},
    { name: 'average_offensiveDuels', label: ''},
    { name: 'average_offensiveDuelsWon', label: ''},
    { name: 'average_offsides', label: ''},
    { name: 'average_opponentHalfRecoveries', label: ''},
    { name: 'average_ownHalfLosses', label: ''},
    { name: 'average_passLength', label: ''},
    { name: 'average_passes', label: ''},
    { name: 'average_passesToFinalThird', label: ''},
    { name: 'average_penalties', label: ''},
    { name: 'average_progressivePasses', label: ''},
    { name: 'average_progressiveRun', label: ''},
    { name: 'average_receivedPass', label: ''},
    { name: 'average_redCards', label: ''},
    { name: 'average_secondAssists', label: ''},
    { name: 'average_shotAssists', label: ''},
    { name: 'average_shotOnTargetAssists', label: ''},
    { name: 'average_shots', label: ''},
    { name: 'average_shotsBlocked', label: ''},
    { name: 'average_shotsOnTarget', label: ''},
    { name: 'average_slidingTackles', label: ''},
    { name: 'average_smartPasses', label: ''},
    { name: 'average_successfulAttackingActions', label: ''},
    { name: 'average_successfulBackPasses', label: ''},
    { name: 'average_successfulCrosses', label: ''},
    { name: 'average_successfulDefensiveAction', label: ''},
    { name: 'average_successfulDribbles', label: ''},
    { name: 'average_successfulForwardPasses', label: ''},
    { name: 'average_successfulGoalKicks', label: ''},
    { name: 'average_successfulKeyPasses', label: ''},
    { name: 'average_successfulLateralPasses', label: ''},
    { name: 'average_successfulLinkupPlays', label: ''},
    { name: 'average_successfulLongPasses', label: ''},
    { name: 'average_successfulPasses', label: ''},
    { name: 'average_successfulPassesToFinalThird', label: ''},
    { name: 'average_successfulPenalties', label: ''},
    { name: 'average_successfulProgressivePasses', label: ''},
    { name: 'average_successfulSlidingTackles', label: ''},
    { name: 'average_successfulSmartPasses', label: ''},
    { name: 'average_successfulThroughPasses', label: ''},
    { name: 'average_successfulVerticalPasses', label: ''},
    { name: 'average_thirdAssists', label: ''},
    { name: 'average_throughPasses', label: ''},
    { name: 'average_touchInBox', label: ''},
    { name: 'average_verticalPasses', label: ''},
    { name: 'average_xgAssist', label: ''},
    { name: 'average_xgSave', label: ''},
    { name: 'average_xgShot', label: ''},
    { name: 'average_yellowCards', label: ''},
    { name: 'percent_aerialDuelsWon', label: ''},
    { name: 'percent_defensiveDuelsWon', label: ''},
    { name: 'percent_directFreeKicksOnTarget', label: ''},
    { name: 'percent_dribblesAgainstWon', label: ''},
    { name: 'percent_duelsWon', label: ''},
    { name: 'percent_fieldAerialDuelsWon', label: ''},
    { name: 'percent_gkAerialDuelsWon', label: ''},
    { name: 'percent_gkSaves', label: ''},
    { name: 'percent_gkSuccessfulExits', label: ''},
    { name: 'percent_goalConversion', label: ''},
    { name: 'percent_headShotsOnTarget', label: ''},
    { name: 'percent_newDefensiveDuelsWon', label: ''},
    { name: 'percent_newDuelsWon', label: ''},
    { name: 'percent_newOffensiveDuelsWon', label: ''},
    { name: 'percent_newSuccessfulDribbles', label: ''},
    { name: 'percent_offensiveDuelsWon', label: ''},
    { name: 'percent_penaltiesConversion', label: ''},
    { name: 'percent_shotsOnTarget', label: ''},
    { name: 'percent_successfulBackPasses', label: ''},
    { name: 'percent_successfulCrosses', label: ''},
    { name: 'percent_successfulDribbles', label: ''},
    { name: 'percent_successfulForwardPasses', label: ''},
    { name: 'percent_successfulGoalKicks', label: ''},
    { name: 'percent_successfulKeyPasses', label: ''},
    { name: 'percent_successfulLateralPasses', label: ''},
    { name: 'percent_successfulLinkupPlays', label: ''},
    { name: 'percent_successfulLongPasses', label: ''},
    { name: 'percent_successfulPasses', label: ''},
    { name: 'percent_successfulPassesToFinalThird', label: ''},
    { name: 'percent_successfulProgressivePasses', label: ''},
    { name: 'percent_successfulShotAssists', label: ''},
    { name: 'percent_successfulSlidingTackles', label: ''},
    { name: 'percent_successfulSmartPasses', label: ''},
    { name: 'percent_successfulThroughPasses', label: ''},
    { name: 'percent_successfulVerticalPasses', label: ''},
    { name: 'percent_win', label: ''},
    { name: 'percent_yellowCardsPerFoul', label: ''},
    { name: 'total_accelerations', label: ''},
    { name: 'total_aerialDuels', label: ''},
    { name: 'total_aerialDuelsWon', label: ''},
    { name: 'total_assists', label: ''},
    { name: 'total_attackingActions', label: ''},
    { name: 'total_backPasses', label: ''},
    { name: 'total_clearances', label: ''},
    { name: 'total_corners', label: ''},
    { name: 'total_counterpressingRecoveries', label: ''},
    { name: 'total_crosses', label: ''},
    { name: 'total_dangerousOpponentHalfRecoveries', label: ''},
    { name: 'total_dangerousOwnHalfLosses', label: ''},
    { name: 'total_defensiveActions', label: ''},
    { name: 'total_defensiveDuels', label: ''},
    { name: 'total_defensiveDuelsWon', label: ''},
    { name: 'total_directFreeKicks', label: ''},
    { name: 'total_directFreeKicksOnTarget', label: ''},
    { name: 'total_directRedCards', label: ''},
    { name: 'total_dribbles', label: ''},
    { name: 'total_dribblesAgainst', label: ''},
    { name: 'total_dribblesAgainstWon', label: ''},
    { name: 'total_duels', label: ''},
    { name: 'total_duelsWon', label: ''},
    { name: 'total_fieldAerialDuels', label: ''},
    { name: 'total_fieldAerialDuelsWon', label: ''},
    { name: 'total_forwardPasses', label: ''},
    { name: 'total_fouls', label: ''},
    { name: 'total_foulsSuffered', label: ''},
    { name: 'total_freeKicks', label: ''},
    { name: 'total_freeKicksOnTarget', label: ''},
    { name: 'total_gkAerialDuels', label: ''},
    { name: 'total_gkAerialDuelsWon', label: ''},
    { name: 'total_gkCleanSheets', label: ''},
    { name: 'total_gkConcededGoals', label: ''},
    { name: 'total_gkExits', label: ''},
    { name: 'total_gkSaves', label: ''},
    { name: 'total_gkShotsAgainst', label: ''},
    { name: 'total_gkSuccessfulExits', label: ''},
    { name: 'total_goalKicks', label: ''},
    { name: 'total_goalKicksLong', label: ''},
    { name: 'total_goalKicksShort', label: ''},
    { name: 'total_goals', label: "Buts"},
    { name: 'total_headShots', label: ''},
    { name: 'total_interceptions', label: ''},
    { name: 'total_keyPasses', label: ''},
    { name: 'total_lateralPasses', label: ''},
    { name: 'total_linkupPlays', label: ''},
    { name: 'total_longPasses', label: ''},
    { name: 'total_looseBallDuels', label: ''},
    { name: 'total_looseBallDuelsWon', label: ''},
    { name: 'total_losses', label: ''},
    { name: 'total_matches', label: ''},
    { name: 'total_matchesComingOff', label: ''},
    { name: 'total_matchesInStart', label: ''},
    { name: 'total_matchesSubstituted', label: ''},
    { name: 'total_minutesOnField', label: "Minutes jouées"},
    { name: 'total_minutesTagged', label: ''},
    { name: 'total_missedBalls', label: ''},
    { name: 'total_newDefensiveDuelsWon', label: ''},
    { name: 'total_newDuelsWon', label: ''},
    { name: 'total_newOffensiveDuelsWon', label: ''},
    { name: 'total_newSuccessfulDribbles', label: ''},
    { name: 'total_offensiveDuels', label: ''},
    { name: 'total_offensiveDuelsWon', label: ''},
    { name: 'total_offsides', label: ''},
    { name: 'total_opponentHalfRecoveries', label: ''},
    { name: 'total_ownHalfLosses', label: ''},
    { name: 'total_passes', label: ''},
    { name: 'total_passesToFinalThird', label: ''},
    { name: 'total_penalties', label: ''},
    { name: 'total_pressingDuels', label: ''},
    { name: 'total_pressingDuelsWon', label: ''},
    { name: 'total_progressivePasses', label: ''},
    { name: 'total_progressiveRun', label: ''},
    { name: 'total_receivedPass', label: ''},
    { name: 'total_recoveries', label: ''},
    { name: 'total_redCards', label: ''},
    { name: 'total_secondAssists', label: ''},
    { name: 'total_shotAssists', label: ''},
    { name: 'total_shotOnTargetAssists', label: ''},
    { name: 'total_shots', label: ''},
    { name: 'total_shotsBlocked', label: ''},
    { name: 'total_shotsOnTarget', label: ''},
    { name: 'total_slidingTackles', label: ''},
    { name: 'total_smartPasses', label: ''},
    { name: 'total_successfulAttackingActions', label: ''},
    { name: 'total_successfulBackPasses', label: ''},
    { name: 'total_successfulCrosses', label: ''},
    { name: 'total_successfulDefensiveAction', label: ''},
    { name: 'total_successfulDribbles', label: ''},
    { name: 'total_successfulForwardPasses', label: ''},
    { name: 'total_successfulGoalKicks', label: ''},
    { name: 'total_successfulKeyPasses', label: ''},
    { name: 'total_successfulLateralPasses', label: ''},
    { name: 'total_successfulLinkupPlays', label: ''},
    { name: 'total_successfulLongPasses', label: ''},
    { name: 'total_successfulPasses', label: ''},
    { name: 'total_successfulPassesToFinalThird', label: ''},
    { name: 'total_successfulPenalties', label: ''},
    { name: 'total_successfulProgressivePasses', label: ''},
    { name: 'total_successfulSlidingTackles', label: ''},
    { name: 'total_successfulSmartPasses', label: ''},
    { name: 'total_successfulThroughPasses', label: ''},
    { name: 'total_successfulVerticalPasses', label: ''},
    { name: 'total_thirdAssists', label: ''},
    { name: 'total_throughPasses', label: ''},
    { name: 'total_touchInBox', label: ''},
    { name: 'total_verticalPasses', label: ''},
    { name: 'total_xgAssist', label: ''},
    { name: 'total_xgSave', label: ''},
    { name: 'total_xgShot', label: ''},
    { name: 'total_yellowCards', label: ''},
    { name: 'total_actions', label: "Total actions"},
    { name: 'total_successfulActions', label: "Total actions réussies"},
]
