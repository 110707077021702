import './MatDropdown.scss';
import { FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, SelectChangeEvent } from "@mui/material";

interface IProps{
    selectOption: (value: string) => void;
    selectedOption: string;
    optionsList: {value: string, name: string}[],
    label?: string;
    externalLabel?: string;
    disabled?: boolean;
    align?: string;
    width?: number | string;
    faClassName?: string;
}

export default function MatDropdown({selectOption, selectedOption, optionsList, label, externalLabel, disabled = false, align, width = '100%', faClassName}: IProps) {

    const handleChange = (event: SelectChangeEvent) => {
        selectOption(event.target.value);
    };

    return (
        <div className={`container align-${align}`}>
            {externalLabel && <h3>{externalLabel}</h3>}
            <FormControl size="small" style={{width}}>
                {label && <InputLabel>{label}</InputLabel>}
                <Select
                    value={selectedOption}
                    renderValue={(value) => optionsList.find(option => option.value == value)?.name }
                    label={label}
                    onChange={handleChange}
                    disabled={disabled}
                >
                    {optionsList.map(option => {
                        if (option.value == selectedOption && faClassName) {
                            return (
                                <MenuItem key={`${option.value}_${option.name}`} value={option.value}>
                                    <ListItemText>
                                        {option.name}
                                    </ListItemText>
                                    <ListItemIcon>
                                        <i className={faClassName}></i>
                                    </ListItemIcon>
                                </MenuItem>
                            )
                        } else {
                            return (
                                <MenuItem key={`${option.value}_${option.name}`} value={option.value}>
                                    <ListItemText>
                                        {option.name}
                                    </ListItemText>
                                </MenuItem>
                            )
                        }
                    })}
                </Select>
            </FormControl>
        </div>
    );
}