// @@ react/redux
import { useNavigate } from "react-router-dom";
import Button from "../Button/Button";

// @@ style
import "./RouteNotFound.scss";

function RouteNotFound() {
  const navigate = useNavigate();

  return (
    <>
      <div className="route-not-found">
        <div className="route-not-found-start">
          <div className="route-not-found-text-container">
            <div className="route-not-found-text-container-head">
              <span>Erreur 404</span>
              <h1>Oupsss !</h1>
            </div>
            <div className="route-not-found-text-container-body">
              <div className="sub-text">
                La page que vous recherchez n'existe pas ou a été déplacée.
                Cliquez ci-dessous pour revenir à l'accueil.
              </div>
              <Button
                faClassName="fal fa-home"
                text="Revenir à l'accueil"
                onClick={() => navigate("/")}
              />
            </div>
          </div>
        </div>
        <div className="route-not-found-end">
          <div className="route-not-found-404"></div>
        </div>
      </div>
    </>
  );
}

export default RouteNotFound;
