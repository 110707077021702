// @@ components
import Header from "../Header/Header";
import GlobalNavbar from "../GlobalNavbar/GlobalNavbar";
import SecondaryNavbar from "../SecondaryNavbar/SecondaryNavbar";

import { Outlet } from "react-router-dom";

// @@ style
import "./TemplateBaseFront.scss";
import { useEffect, useState } from "react";
import configService from "../../services/config.service";

export default function TemplateBaseFront() {

  const [loading, setLoading] = useState(true)

  useEffect(() =>{
    async function fetchData(){
      await configService.updateAxiosAuthorization();
      setLoading(false);
    }
    fetchData();
    
  }, [])

  return (
    <>
      <GlobalNavbar />
      { !loading && <div className="site-content">
        <Outlet />
      </div> }
    </>
  );
}
