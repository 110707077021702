// @@ components
import Dropdown from "../../components/Dropdown/Dropdown";

// @@ enums
import EButtonType from "./Enums/EButtonType.enum";
import EButtonSize from "./Enums/EButtonSize.enum";

// @@ interfaces
import IBubble from "./Interfaces/IBubble.interface";
import IDropdown from "../Dropdown/Interfaces/IDropdown.interface";

// @@ style
import "./Button.scss";

// @@ react
import { useEffect, useState } from "react";
import EButtonTypeForm from "./Enums/EButtonTypeForm.enum";
import Loader from "../Loader/Loader";

interface IButton {
  faClassName?: string;
  text?: string;
  type?: EButtonType;
  size?: EButtonSize;
  imageUrl?: string;
  title?: string;
  dropdown?: IDropdown;
  onClick?: () => void;
  btnType?: EButtonTypeForm;
  isLoading?: boolean;
  disabled?: boolean;
}

export default function Button({
  faClassName,
  text,
  type = EButtonType.primary,
  size = EButtonSize.regular,
  imageUrl,
  title,
  dropdown,
  onClick,
  btnType = EButtonTypeForm.submit,
  isLoading = false,
  disabled = false,
}: IButton) {
  const {
    dropdownItems,
    dropdownAlign,
    dropdownItemsType,
    onDropdownItemClick,
    onSelectedDropdownItemsChange,
  } = dropdown ?? {};
  const [bubbles, setBubbles] = useState<IBubble[]>([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);

  useEffect(() => {
    function handleDropdownClosingOnClick(e: any) {
      const { pageX, pageY } = e;
      const clickedElement = document.elementFromPoint(pageX, pageY);
      const hasClickedOutsideDropdown = clickedElement?.closest(".dropdown");

      if (dropdownItemsType?.includes("multi") && hasClickedOutsideDropdown)
        return;

      setIsDropdownVisible(false);
      window.removeEventListener("click", handleDropdownClosingOnClick);
    }

    isDropdownVisible &&
      window.addEventListener("click", handleDropdownClosingOnClick);
  }, [isDropdownVisible]);

  function handleClick(e: any) {
    handleBubbleEffect: {
      const now = Date.now();
      const { pageX, pageY } = e;
      const clickedElement = document.elementFromPoint(pageX, pageY);
      const buttonElement = clickedElement?.closest("a.button");
      const { left, top } = buttonElement?.getBoundingClientRect() ?? {
        left: 0,
        top: 0,
      };
      const x = pageX - left;
      const y = pageY - top;
      const bubble = { x, y, createdAt: now };

      setBubbles([...bubbles, bubble]);
    }

    handleDropdownToggle: {
      setIsDropdownVisible(!isDropdownVisible);
    }

    handleCustomAction: {
      onClick?.();
    }
  }

  return (
    <div className="button-container">
      {!isLoading ? (
        <button
          title={title}
          className="button"
          data-type={type}
          data-size={size}
          type={btnType}
          onClick={handleClick}
          disabled={disabled}
          style={{ backgroundImage: `url("${imageUrl}")` }}
        >
          {faClassName && <i className={faClassName}></i>}
          {text && <span>{text}</span>}
          {bubbles.length
            ? bubbles.map(({ x, y }: IBubble, bubbleIndex: number) => {
                const bubbleStyle = { top: `${y}px`, left: `${x}px` };

                return (
                  <div
                    key={bubbleIndex}
                    className="bubble-effect"
                    style={bubbleStyle}
                  ></div>
                );
              })
            : ""}
        </button>
      ) : (
        <Loader />
      )}
      {dropdownItems && dropdownItemsType ? (
        <Dropdown
          dropdownItems={dropdownItems}
          dropdownAlign={dropdownAlign}
          dropdownItemsType={dropdownItemsType}
          isDropdownVisible={isDropdownVisible}
          onDropdownItemClick={onDropdownItemClick}
          onSelectedDropdownItemsChange={onSelectedDropdownItemsChange}
        />
      ) : (
        ""
      )}
    </div>
  );
}
