import { Radio } from "@mui/material";
import React from "react";
import { useState } from "react";
import useOutsideClick from "../../hooks/use-outside-click";
import { Player } from "../../models/Player"
import FootballField from "../FootballField/FootballField"
import { CustomRadio } from "../Forms/CustomRadio";
import { CustomSlider } from "../Forms/CustomSlider";
import FilterIconSvg from "../Images/FilterIconSvg"
import './OccupationField.scss';

interface IProps{
    players: Player[],
    season: string
}
export default function OccupationField({players, season}: IProps){
    const [range, setRange] = useState(15);
    const [widthRadius, setWidthRadius] = useState('1');
    const [showFilters, setShowFilters] = useState(false);
    const ref = React.useRef<HTMLDivElement>(null)
    useOutsideClick(() => setShowFilters(false), ref)

    
    return  <>
        <div className="space-between">
            <h3>Occupation du terrain</h3>
        
            <div className="filter" ref={ref}>
                <FilterIconSvg onClick={() => setShowFilters(!showFilters)}/>
                { showFilters && <div className="modal-filters" >
                    <CustomSlider 
                        valueLabelDisplay="auto"
                        aria-label="pretto slider"
                        defaultValue={range}
                        value={range}
                        onChange={(e: any) => setRange(Number(e.target.value) )}
                        min={1} 
                        max={30}
                    />

                    <fieldset>
                        <legend>Densité de points</legend>

                        <div>
                            <CustomRadio
                                checked={widthRadius == '1'}
                                onChange={(e: any) => setWidthRadius(e.target.value)}
                                value="1"
                                name="radio-buttons"
                                inputProps={{ 'aria-label': '1' }}
                            />
                            <label>100%</label>
                        </div>

                        <div>
                            <CustomRadio
                                checked={widthRadius == '2'}
                                onChange={(e: any) => setWidthRadius(e.target.value)}
                                value="2"
                                name="radio-buttons"
                                inputProps={{ 'aria-label': '2' }}
                            />
                            <label>75%</label>
                        </div>

                        <div>
                            <Radio
                                checked={widthRadius == '4'}
                                onChange={(e: any) => setWidthRadius(e.target.value)}
                                value="4"
                                name="radio-buttons"
                                inputProps={{ 'aria-label': '4' }}
                            />
                            <label>50%</label>
                        </div>

                        <div>
                            <Radio
                            checked={widthRadius == '6'}
                                onChange={(e: any) => setWidthRadius(e.target.value)}
                                value="6"
                                name="radio-buttons"
                                inputProps={{ 'aria-label': '6' }}
                            />
                            <label>10%</label>
                        </div>
                    </fieldset>
                </div> }
            </div>
        </div>

       

        <div className="football-fields">
            {players.map((player: Player, index: number) => 
                <FootballField  key={`player_${player.id}_${index}`} player={player} season={season} index={index} range={range} widthRadius={widthRadius} />
            )}
        </div>
    </>
}