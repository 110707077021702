import Axios from "axios";

async function updateAxiosAuthorization() {
    await Axios.interceptors.request.use((config: any) => {
      config.headers.Authorization = "Bearer test";
  
      return config;
    });
  }

  export default {
    updateAxiosAuthorization
  }