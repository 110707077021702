import { Dialog, DialogContent, DialogTitle, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import { useEffect, useState } from "react";
import { Player } from "../../models/Player";
import statsService from "../../services/stats.service";
import FullScreenSvg from "../Images/FullScreenSvg";
import './FootballField.scss';

interface IProps{
    player: Player,
    season: string,
    index: number,
    range: number,
    widthRadius: string,
    height?: number,
    width?: number,
    color?: string,
}

interface IData{
    x: number,
    y: number,
    value: number,
}
export default function FootballField({player, season, index, range, widthRadius}: IProps){
    const height= 139*0.7;
    const width= 220*0.7;

    const [data, setData] = useState<IData[]>([]);

    const [loading, setLoading] = useState(true);
    const [showDialog, setShowDialog] = useState(false);


    useEffect(() => {

        async function fetchData(){
            setLoading(true);
            const res = await statsService.getSeasonLocation(player.id, season, Number(widthRadius));
            let resParsed: IData[] = [];
            for (let [key, value] of Object.entries(res)) {
                //let newKey = key.slice(key.length - 1, 1);
                key = key.replace('(', '');
                key = key.replace(')', '');
                const splittedKey = key.split(', ');

                
    
                resParsed = [...resParsed, {x: Number(splittedKey[0]), y: Number(splittedKey[1]), value: Number(value)} ];
            }

            setData(resParsed)
            setLoading(false);
    
        }
        fetchData();
    }, [widthRadius, season])

    const Transition = React.forwardRef(function Transition(
        props: TransitionProps & {
          children: React.ReactElement<any, any>;
        },
        ref: React.Ref<unknown>,
      ) {
        return <Slide direction="up" ref={ref} {...props} />;
      });

    return <>
        <div className="card">
            {!loading && <>
                <div className="flex-row between">
                    <div key={`player_${player.id}_${index}`} className="player" style={{borderColor: player.getColorByIndex(index)}}>
                        <img src={player.image} />         
                    </div>  
                    <FullScreenSvg onClick={() => setShowDialog(true)}/>   
                </div>
                <FootballFieldSvg  data={data} rangeRadius={range} widthRadius={Number(widthRadius)} height={height} width={width}  player={player} index={index} color={player.getColorByIndex(index)} />
                
            </> }
        </div>
        {showDialog && <Dialog
            open={showDialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={() =>  setShowDialog(false)}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>
                <div className="flex-row">
                    <div key={`player_${player.id}_${index}`} className="player" style={{borderColor: player.getColorByIndex(index), marginRight: 10}}>
                        <img src={player.image} />         
                    </div>  
                    {player.first_name} {player.last_name}
                </div>
            </DialogTitle>
            <DialogContent>
                <FootballFieldSvg  data={data} rangeRadius={range} widthRadius={Number(widthRadius)} height={height * 3.5} width={width * 3.5}  player={player} index={index} color={player.getColorByIndex(index)} />
            </DialogContent>
          
        </Dialog> }
    </>
}


interface IPropsSvg{
    data: IData[],
    rangeRadius: number,
    widthRadius: number,
    player: Player,
    index: number,
    height?: number,
    width?: number,
    color?: string
}
enum EWidthtRadius{
    OPTION_DEFAULT=1,
    OPTION1=2,
    OPTION2=4,
    OPTION3=6
}
function FootballFieldSvg({data, rangeRadius, widthRadius, player, index, height = 139, width = 220, color = 'black'}: IPropsSvg){

    function getWidthRadius(){
        if(widthRadius == 1){
            return EWidthtRadius.OPTION_DEFAULT
        } else if(widthRadius < 10){
            return EWidthtRadius.OPTION1;
        } else if(widthRadius < 20){
            return EWidthtRadius.OPTION2;
        }

        return EWidthtRadius.OPTION3;
    }

    function getData(){
        /*const radius = getWidthRadius();
        let dataPosition:IData[] = []
        for(let x = 0; x < 105; x = x + radius){
            for(let y = 0; y < 105; y = y + radius){
                const resFiltered = data.filter(item => item.x >= x && item.y >= y && item.x < x + radius && item.y < y + radius );
                
                if(resFiltered.length){
                    dataPosition = [
                        ...dataPosition,
                        {
                            x: x, 
                            y: y, 
                            value: resFiltered.reduce((total, next) => total + next.value, 0) / resFiltered.length
                        }
                    ]
                } else {
                    dataPosition = [
                        ...dataPosition,
                        {
                            x: x, 
                            y: y, 
                            value: 0
                        }
                    ]
                }
            }
        }

        console.log(data);

        return dataPosition;*/

        return data;
    }

    function getRadius(initialValue: number){
        const multiplicator = getWidthRadius() == EWidthtRadius.OPTION_DEFAULT ? 1.2 : getWidthRadius() == EWidthtRadius.OPTION1 ? 1 : getWidthRadius() == EWidthtRadius.OPTION2 ? 1.2 : getWidthRadius() == EWidthtRadius.OPTION3 ? 1.4 : 1;
        const value = initialValue > 1 ? 1 : initialValue;

        return value * rangeRadius * multiplicator;
    }

    function getCX(x: number){
        const initialX = 2.104788;

        return 5 + (initialX * x);
    }

    function getCY(y: number){
        const initialY =  1.286084;
        
        return 5 + (initialY * y);
    }


    return <svg width={width} height={height} viewBox="0 0 220 139" fill="none" xmlns="http://www.w3.org/2000/svg">

        <rect x="0.5" y="0.5" width="219" height="137.913" stroke="#667085"/>
        <rect x="0.5" y="37.0052" width="34.859" height="65.2261" stroke="#667085"/>
        <rect x="184.641" y="37.0052" width="34.859" height="65.2261" stroke="#667085"/>
        <rect x="0.5" y="53.481" width="11.9222" height="31.6285" stroke="#667085"/>
        <rect x="207.578" y="53.481" width="11.9222" height="31.6285" stroke="#667085"/>
        <line x1="110.661" x2="110.661" y2="138.913" stroke="#667085"/>
        <circle cx="110" cy="69.6182" r="20.0139" stroke="#667085"/>
        <path d="M4.63478 0.296554C4.63478 2.69238 2.69258 4.63458 0.296753 4.63458" stroke="#667085"/>
        <path d="M0.322983 134.391C2.71881 134.391 4.66101 136.333 4.66101 138.729" stroke="#667085"/>
        <path d="M215.477 138.729C215.477 136.333 217.419 134.391 219.815 134.391" stroke="#667085"/>
        <path d="M219.704 4.63478C217.308 4.63478 215.365 2.69258 215.365 0.296753" stroke="#667085"/>
        <mask id="path-102-inside-1_301_40392" fill="white">
        <path fillRule="evenodd" clipRule="evenodd" d="M35.859 85.6095C40.8118 81.8894 44.0154 75.9664 44.0154 69.2952C44.0154 62.6241 40.8118 56.7011 35.859 52.981V54.2503C40.226 57.8059 43.0154 63.2248 43.0154 69.2952C43.0154 75.3657 40.226 80.7846 35.859 84.3401V85.6095Z"/>
        </mask>
        <path d="M35.859 85.6095H34.859V87.6113L36.4596 86.4091L35.859 85.6095ZM35.859 52.981L36.4596 52.1814L34.859 50.9791V52.981H35.859ZM35.859 54.2503H34.859V54.7257L35.2276 55.0258L35.859 54.2503ZM35.859 84.3401L35.2276 83.5647L34.859 83.8648V84.3401H35.859ZM36.4596 86.4091C41.653 82.5082 45.0154 76.2942 45.0154 69.2952H43.0154C43.0154 75.6386 39.9705 81.2705 35.2584 84.81L36.4596 86.4091ZM45.0154 69.2952C45.0154 62.2962 41.653 56.0823 36.4596 52.1814L35.2584 53.7805C39.9705 57.3199 43.0154 62.9519 43.0154 69.2952H45.0154ZM34.859 52.981V54.2503H36.859V52.981H34.859ZM44.0154 69.2952C44.0154 62.9114 41.0806 57.2122 36.4904 53.4749L35.2276 55.0258C39.3714 58.3996 42.0154 63.5383 42.0154 69.2952H44.0154ZM36.4904 85.1156C41.0806 81.3783 44.0154 75.6791 44.0154 69.2952H42.0154C42.0154 75.0522 39.3714 80.1908 35.2276 83.5647L36.4904 85.1156ZM34.859 84.3401V85.6095H36.859V84.3401H34.859Z" fill="#667085" mask="url(#path-102-inside-1_301_40392)"/>
        <mask id="path-104-inside-2_301_40392" fill="white">
        <path fillRule="evenodd" clipRule="evenodd" d="M184.221 53.3039C179.268 57.0241 176.065 62.947 176.065 69.6182C176.065 76.2894 179.268 82.2123 184.221 85.9325L184.221 84.6631C179.854 81.1075 177.065 75.6886 177.065 69.6182C177.065 63.5478 179.854 58.1289 184.221 54.5733L184.221 53.3039Z"/>
        </mask>
        <path d="M184.221 53.3039L185.221 53.3039L185.221 51.3021L183.62 52.5044L184.221 53.3039ZM184.221 85.9325L183.62 86.7321L185.221 87.9343L185.221 85.9325L184.221 85.9325ZM184.221 84.6631L185.221 84.6631L185.221 84.1878L184.852 83.8876L184.221 84.6631ZM184.221 54.5733L184.852 55.3488L185.221 55.0487L185.221 54.5733L184.221 54.5733ZM183.62 52.5044C178.427 56.4053 175.065 62.6192 175.065 69.6182L177.065 69.6182C177.065 63.2748 180.109 57.6429 184.822 54.1035L183.62 52.5044ZM175.065 69.6182C175.065 76.6172 178.427 82.8312 183.62 86.7321L184.822 85.1329C180.109 81.5935 177.065 75.9616 177.065 69.6182L175.065 69.6182ZM185.221 85.9325L185.221 84.6631L183.221 84.6631L183.221 85.9325L185.221 85.9325ZM176.065 69.6182C176.065 76.0021 178.999 81.7013 183.59 85.4386L184.852 83.8876C180.709 80.5138 178.065 75.3752 178.065 69.6182L176.065 69.6182ZM183.59 53.7978C178.999 57.5352 176.065 63.2343 176.065 69.6182L178.065 69.6182C178.065 63.8613 180.709 58.7226 184.852 55.3488L183.59 53.7978ZM185.221 54.5733L185.221 53.3039L183.221 53.3039L183.221 54.5733L185.221 54.5733Z" fill="#667085" mask="url(#path-104-inside-2_301_40392)"/>

        {getData().map((item: IData, index: number) => 
            <circle fillOpacity="0.5" key={`item_circle_${index}`} cx={getCX(item.x)} cy={getCY(item.y)} r={getRadius(item.value)} /*r="0.478261"*/ fill={color}/>
        )}
    </svg>
}