import {
    createBrowserRouter,
  } from "react-router-dom";
import RouteNotFound from "../components/RouteNotFound/RouteNotFound";
import TemplateBaseFront from "../components/TemplateBaseFront/TemplateBaseFront";
import HomePage from "../pages/logged/HomePage";
import Login from '../pages/login/Login';

const router = createBrowserRouter([
    {
      path: "/",
      element: <TemplateBaseFront />,
      errorElement: <RouteNotFound />,
      children: [
        {
          path: "",
          element: <HomePage />
        },
      ],
    },
    {
      path: "/login",
      element: <Login />,
    },
]);

export default router;