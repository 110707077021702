export class Player{
    id: number;
    first_name: string;
    middle_name: string;
    last_name: string;
    height: number;
    weight: number;
    birth_date: Date;
    role: string;
    main_foot: string;
    gender: string;
    status: string;
    image: string;

    constructor(data: any){
        this.id = data.id;
        this.first_name = data.first_name;
        this.middle_name = data.middle_name;
        this.last_name = data.last_name;
        this.height = data.height;
        this.weight = data.weight;
        this.birth_date = new Date(data.birth_date);
        this.role = data.role;
        this.main_foot = data.main_foot;
        this.gender = data.gender;
        this.status = data.status;
        this.image = data.image;
    }

    getColorByIndex(index: number){
        if(index == 0){
            return '#034D78';
        } else if(index == 1){
            return '#FDB300';
        } else if(index == 2){
            return '#427A9A';
        } else if(index == 3){
            return "#2E90FA";
        } else if(index == 4){
            return "#FED87D";
        }

        return 'black';
    }
}