import { Tooltip } from "@mui/material";
import { Player } from "../../models/Player";
import './PlayerImg.scss';

interface IProps {
    player: Player;
    squareWidth: number;
    index?: number;
}
export default function PlayerImg ({player, index, squareWidth = 40}:IProps){
    return (
        <Tooltip title={`${player.first_name} ${player.last_name}`} placement="top-end">
            <img alt={`${player.first_name} ${player.last_name}`} src={player.image} style={{borderColor: index ? player.getColorByIndex(index) : '#034D78', width: squareWidth, height: squareWidth, borderRadius: squareWidth}}/>
        </Tooltip>
    )
} 