
export class League{
    id: number;
    name: string;
    label: string;

    constructor(data: any){
        this.id = data.id;
        this.name = data.name;
        this.label = data.label;
    }
}

export function getLeagues(){
    return leagues.map(league => new League(league))
}

const leagues = [
    {name: 'LIGUE_1_SEASON_2022_2023_ID', label: "2022/2023", id: 188122},
    {name: 'LIGUE_1_SEASON_2021_2022_ID', label: "2021/2022", id: 187374},
    {name: 'LIGUE_1_SEASON_2020_2021_ID', label: "2020/2021", id: 186243},
]

