
interface IProps{
    onClick: () => void;
}
function FilterIconSvg({onClick}: IProps) {
    return <svg onClick={onClick} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_516_26219)">
            <path d="M9.99967 10.8334C10.4599 10.8334 10.833 10.4603 10.833 10C10.833 9.53978 10.4599 9.16669 9.99967 9.16669C9.53944 9.16669 9.16634 9.53978 9.16634 10C9.16634 10.4603 9.53944 10.8334 9.99967 10.8334Z" stroke="#475467" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15.833 10.8334C16.2932 10.8334 16.6663 10.4603 16.6663 10C16.6663 9.53978 16.2932 9.16669 15.833 9.16669C15.3728 9.16669 14.9997 9.53978 14.9997 10C14.9997 10.4603 15.3728 10.8334 15.833 10.8334Z" stroke="#475467" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4.16634 10.8334C4.62658 10.8334 4.99967 10.4603 4.99967 10C4.99967 9.53978 4.62658 9.16669 4.16634 9.16669C3.7061 9.16669 3.33301 9.53978 3.33301 10C3.33301 10.4603 3.7061 10.8334 4.16634 10.8334Z" stroke="#475467" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_516_26219">
            <rect width="20" height="20" rx="8" fill="white"/>
            </clipPath>
        </defs>
    </svg>
    
}

export default FilterIconSvg;