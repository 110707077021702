import axios from "axios";

async function getSeasonLocation(playerId: number, seasonId: string = '188122', rounding = 1){
    const res = await axios.get(`${process.env.REACT_APP_SKRINNERS_API}/api/v1/players/${playerId}/season_location/${seasonId}?rounding=${rounding}`)
    
    return res.data;
}

export default {
    getSeasonLocation
}