import { useState } from 'react';
import './SecondaryNavbar.scss';

interface IProps{
    data: {name: string, label: string}[];
    currentNav: any,
    setCurrentNav: (item: any) => void
}
export default function SecondaryNavbar({data, currentNav, setCurrentNav}: IProps) {

    return (
        <div className="secondary-navbar">
            {data.map(item => 
                <div key={`nav_item_${item.name}`} className="secondary-navbar-start" onClick={() => setCurrentNav(item)}>
                    <div
                        className={ `secondary-navbar-item ${currentNav.name === item.name ? 'active' : ''}`}
                    >
                        <span>{item.label}</span>    
                    </div>    
                </div>
            )}
            <div className="secondary-navbar-end"></div>
        </div>
    );
};
