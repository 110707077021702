import axios from "axios";
import { Player } from "../models/Player";
import { PlayerStats } from "../models/PlayerStats";

async function get(playerId: string, seasonId: string){
    const res = await axios.get(`${process.env.REACT_APP_SKRINNERS_API}/api/v1/players/${playerId}/season_stats/${seasonId}`)
    const playerStats: Object = {}
    Object.entries(res.data).forEach(([typeKey, value]: any) => {
        Object.entries(value).forEach(([key, value]: any) => {
            playerStats[`${typeKey}_${key}` as keyof Object] = value
        })
    })
    return new PlayerStats(playerStats)
}

export default {
    get
}