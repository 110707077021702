import "./Panel.scss";
import { useEffect, useState } from "react";
import IconCloseSvg from "../Images/IconCloseSvg";

interface IProps{
  children: any;
  exitPanel: () => void;
  closing: boolean,
  title: string,
  panelWidth?: number;
}

export default function Panel({children, exitPanel, closing, title, panelWidth=600}: IProps) {

  const [reverse, setReverse] = useState(false)

  function closePanel(){
    setReverse(true)
    setTimeout(() => exitPanel(), 400)
  }

  useEffect(() => {
    if (closing) {
      closePanel()
    }
  },[closing])

  return (
    <div className="panel-container">
      <div className="click-outside" onClick={() => closePanel()}></div>
      <div className={reverse ? 'panel-reverse w-'+panelWidth : 'panel w-'+panelWidth}>
          <div className="title-section">
              <h2>{ title }</h2>
              <IconCloseSvg className="icon-close" onClick={() => closePanel()}/>
          </div>
          { children }
      </div>
    </div>
  );
}
