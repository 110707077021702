import "./AddPlayerPanel.scss";
import { useEffect, useState } from "react";
import Button from "../Button/Button";
import Panel from "../Panel/Panel";
import EButtonType from "../Button/Enums/EButtonType.enum";
import MatDropdown from "../MatDropdown/MatDropdown";
import playerService from "../../services/player.service";
import { Player } from "../../models/Player";
import MatSearch from "../MatSearch/MatSearch";
import { InputAdornment, ListItemIcon, ListItemText, MenuItem, TextField } from "@mui/material";
import PlayerImg from "../PlayerImg/PlayerImg";

interface IProps{
  exitPanel: () => void;
  addPlayer: (player: Player) => void;
  playersListId: number[];
}

export default function AddPlayerPanel({ exitPanel, addPlayer, playersListId }: IProps) {

  const [loading, setLoading] = useState<boolean>(false)
  const [closePanel, setClosePanel] = useState<boolean>(false)
  const [selectedPlayer, setSelectedPlayer] = useState<Player>()
  const [playersList, setPLayersList] = useState<Player[]>([])
  const [search, setSearch] = useState<string>('')

  //DEBOUNCE
  useEffect(() => {
    setLoading(true)
    const searchPlayers = setTimeout(async () => {
        let newPlayersList: Player[] = await playerService.list(search)
        setPLayersList(newPlayersList.filter(player => !playersListId.includes(player.id)))
        setLoading(false)
    }, 400)

    return () => clearTimeout(searchPlayers)
  }, [search])
  //DEBOUNCE
  
  return (
    <Panel exitPanel={() => exitPanel()} title={"Ajouter un joueur"} closing={closePanel}>
      <div className="content">
        <div className="form">
          <MatDropdown
            selectOption={() => null}
            selectedOption={'0'} 
            optionsList={[
              {value: '0', name: 'France'},
            ]}
            disabled={true}
            externalLabel={"Pays"}
          />
          <MatDropdown
            selectOption={() => null}
            selectedOption={'0'} 
            optionsList={[
              {value: '0', name: 'Ligue 1 - Uber Eats'},
            ]}
            disabled={true}
            externalLabel={"Ligue"}
          />
          <MatSearch
            loading={loading}
            selectOption={(value) => setSelectedPlayer(value)}
            optionsList={playersList}
            selectedOption={selectedPlayer}
            externalLabel={"Joueur"}
            onSearch={(value) => setSearch(value)}
            getOptionLabel={(option: Player) => `${option.first_name} ${option.last_name}`}
            getOptionDisplay={(props, option, state) => {
                return (
                  <MenuItem  {...props} key={`${option.id}_player`}>
                      <ListItemIcon>
                        <img src={option.image} />
                      </ListItemIcon>
                      <ListItemText>
                        {`${option.first_name} ${option.last_name}`}
                      </ListItemText>
                  </MenuItem>
                )
              }
            }
            renderInput={(params: any) => {
              return (
                <TextField 
                  {...params} 
                  onChange={(event) => setSearch(event.target.value)}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        <InputAdornment position="end">
                          {selectedPlayer && <PlayerImg player={selectedPlayer} squareWidth={30}/>}
                        </InputAdornment>
                        {params.InputProps.startAdornment}
                      </>
                    )
                  }}
                />
              )
            }}
          />

        </div>
        <div className="buttons">
          <Button
            type={EButtonType.secondary}
            faClassName="fal fa-xmark"
            text="Annuler"
            title="Annuler"
            onClick={async () => {
              setClosePanel(true)
            }}
          />
          <Button
            disabled={!selectedPlayer}
            faClassName="fal fa-plus"
            text="Ajouter"
            title="Ajouter"
            onClick={async () => {
              selectedPlayer && addPlayer(selectedPlayer)
              setClosePanel(true)
                // const players = await playerService.list('Sarabia');
                // addPlayer(players[0]);
            }}
          />

        </div>
      </div>
    </Panel>
  );
}
