import { Player } from "../../models/Player";
import PlayerImg from "../PlayerImg/PlayerImg";
import './PlayerList.scss';

interface IProps{
    players: Player[];
    deletePlayer: (player: Player) => void;
}
export default function PlayerList({players, deletePlayer}: IProps){

    return <div className="player-list">
        {players.map((player: Player, index: number) => 
            <div key={`player_${player.id}_${index}`} className="player" style={{borderColor: player.getColorByIndex(index)}}>
                <i className="fal fa-circle-xmark delete-player" onClick={() => deletePlayer(player)}></i>
                <PlayerImg player={player} index={index} squareWidth={40}/>
            </div>    
        )}
    </div>
}