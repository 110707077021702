import {  useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import SecondaryNavbar from "../../components/SecondaryNavbar/SecondaryNavbar";
import { getPositionPlayerList, PositionPlayer } from "../../models/utils/PositionPlayer";
import Button from "../../components/Button/Button";
import './HomePage.scss';
import { Player } from "../../models/Player";
import PlayerList from "../../components/PlayerList/PlayerList";
import Block from "../../components/Block/Block";
import FootballField from "../../components/FootballField/FootballField";
import playerService from "../../services/player.service";
import FilterIconSvg from "../../components/Images/FilterIconSvg";
import OccupationField from "../../components/OccupationField/OccupationField";
import AddPlayerPanel from "../../components/AddPlayer/AddPlayerPanel";
import MatDropdown from "../../components/MatDropdown/MatDropdown";
import { getLeagues } from "../../models/utils/Leagues";
import playerStatsService from "../../services/playerStats.service";
import { PlayerInfos } from "../../models/utils/PlayerInfos";
import StatsPlayers from "../../components/StatsPlayers/StatsPlayers";
import { PlayerStats } from "../../models/PlayerStats";
import EmptyStatsPlayers from "../../components/StatsPlayers/EmptyStatsPlayers/EmptyStatsPlayers";

export default function HomePage(){
    const positions: PositionPlayer[] = getPositionPlayerList();
    const [currentPosition, setCurrentPosition] = useState<PositionPlayer>(positions[0]);
    const [playersInfos, setPlayersInfos] = useState<PlayerInfos[]>([]);
    const [showPanel, setShowPanel] = useState<boolean>(false);
    
    const leagues = getLeagues()
    const [selectedLeague, setSelectedLeague] = useState<string>(leagues[0].id.toString());

    const addPlayer = async (player: Player) => {
        let newPlayerInfo = new PlayerInfos({
            player: player,
            stats: await playerStatsService.get(player.id.toString(), selectedLeague)
        })
        setPlayersInfos([...playersInfos, newPlayerInfo]);
        localStorage.setItem(currentPosition.name, JSON.stringify([...playersInfos, newPlayerInfo]))
    }
    
    const selectLeague = async (league: string) => {
        console.log(league);
        setSelectedLeague(league)
        let newPlayerInfosArray = await Promise.all(
            playersInfos.map(async (playerInfo: PlayerInfos) => {
                let newPlayerInfo = new PlayerInfos({
                    player: playerInfo.player,
                    stats: await playerStatsService.get(playerInfo.player.id.toString(), league)
                })
                return newPlayerInfo
            })
        )
        setPlayersInfos(newPlayerInfosArray);
        localStorage.setItem(currentPosition.name, JSON.stringify(newPlayerInfosArray))
    }

    const deletePlayer = (player: Player) => {
        setPlayersInfos(playersInfos.filter(playerInfo => playerInfo.player.id !== player.id));
        localStorage.setItem(currentPosition.name, JSON.stringify(playersInfos.filter(playerInfo => playerInfo.player.id !== player.id)))
    }

    useEffect(() => {
        let newPlayersInfos = localStorage.getItem(currentPosition.name)
        if (newPlayersInfos) {
            let infosToSet = JSON.parse(newPlayersInfos).map((playerInfo: PlayerInfos) => {
                return new PlayerInfos({player: new Player(playerInfo.player), stats: new PlayerStats(playerInfo.stats)})
            })
            const refresh = async () => {
                let newPlayerInfosArray = await Promise.all(
                    infosToSet.map(async (playerInfo: PlayerInfos) => {
                        let newPlayerInfo = new PlayerInfos({
                            player: playerInfo.player,
                            stats: await playerStatsService.get(playerInfo.player.id.toString(), selectedLeague)
                        })
                        return newPlayerInfo
                    })
                )
                setPlayersInfos(newPlayerInfosArray);
                localStorage.setItem(currentPosition.name, JSON.stringify(newPlayerInfosArray))
            }
            refresh()
        } else {
            setPlayersInfos([])
        }
    }, [currentPosition])

    return <>
        <Header title="Comparateur de joueurs"/>
        <main>
            <SecondaryNavbar data={positions} currentNav={currentPosition} setCurrentNav={(item => setCurrentPosition(item))}/>
            <div className="page">
                <div className="header-players">
                    <Button
                        disabled={playersInfos && playersInfos.length >= 5}
                        faClassName="fal fa-plus"
                        text="Ajouter un joueur"
                        title="Ajouter un joueur"
                        onClick={async () => {
                            setShowPanel(true)
                        }}
                    />

                    <PlayerList players={playersInfos.map(playerInfo => playerInfo.player)} deletePlayer={deletePlayer}/>
                    <MatDropdown
                        width={260}
                        align="right"
                        selectOption={selectLeague}
                        selectedOption={selectedLeague} 
                        optionsList={leagues.map(league => { return {name: league.label, value: league.id.toString()}})}
                        disabled={false}
                        label={"Saison"}
                        faClassName="fal fa-check"
                    />
                </div>
                <div className="main-content">
                    <Block>
                        <h3>Caractéristiques techniques</h3>
                        {playersInfos && playersInfos.length !== 0 ? 
                            <StatsPlayers currentPosition={currentPosition} playerInfos={playersInfos}/>
                            :
                            <EmptyStatsPlayers/>
                        }
                    </Block>

                    {playersInfos.length > 0 && <Block>
                        <OccupationField season={selectedLeague} players={playersInfos.map(item => item.player)}/>
                    </Block> }
                </div>
            </div>
            
        </main>
        { showPanel && 
            <AddPlayerPanel exitPanel={() => setShowPanel(false)} addPlayer={addPlayer} playersListId={playersInfos.map(playerInfo => playerInfo.player.id)}/>
        }
    </>
}