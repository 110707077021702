import { useNavigate } from "react-router-dom";

import "./GlobalNavbar.scss";

export default function GlobalNavbar() {
  const navigate = useNavigate();

  return (
    <>
      <div className="global-navbar">
        <div className="global-navbar-head">
          <div
            className="global-navbar-item global-navbar-main-item"
          >
            <img src="/images/logo-skrinners.png" alt="logo" />
          </div>
        </div>
        <div className="global-navbar-body">
          <div className="global-navbar-body-start">
          
              <div
                className={`global-navbar-item active`}
                onClick={() => navigate('')}
              >
                <img src="/images/icons/stats-icon.png" />
              </div>
                
          </div>
          <div className="global-navbar-body-end">
            <div
              className="global-navbar-item"

            >
               <img src="/images/icons/settings-icon.png" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}