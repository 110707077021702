import { SVGProps } from "react"

const FullScreenSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.917 7.333 16.75 1.5m0 0h-5m5 0v5m-9.167 4.167L1.75 16.5m0 0h5m-5 0v-5"
      stroke="#344054"
      strokeWidth={1.667}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default FullScreenSvg
