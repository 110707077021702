import { Autocomplete,  FormControl,  MenuItem,  TextField } from "@mui/material";

interface IProps{
    selectOption: (value: any) => void;
    onSearch: (value: string) => void;
    getOptionLabel: (option: any) => string;
    selectedOption: any | null;
    optionsList: any[],
    loading: boolean,
    getOptionDisplay?: (props:any, option: any, state:any) => JSX.Element;
    renderInput?: (params:any) => JSX.Element;
    label?: string;
    externalLabel?: string;
    disabled?: boolean;
}

export default function MatSearch({
    selectOption,
    onSearch,
    getOptionLabel,
    selectedOption,
    optionsList,
    loading,
    getOptionDisplay,
    renderInput = (params: any) => <TextField {...params} label={label} onChange={(event) => onSearch(event.target.value)} />,
    label,
    externalLabel,
    disabled = false
}: IProps) {

    return (
        <div>
            {externalLabel && <h3>{externalLabel}</h3>}
            <FormControl fullWidth size="small">
                <Autocomplete
                    onClose={(event, reason) => {
                        if (reason !== "selectOption" && (selectedOption == null || !optionsList.find(option => option.id == selectedOption.id))) {
                            selectOption(null)
                        }
                    }}
                    freeSolo
                    loading={loading}
                    onChange={(event, value) => selectOption(value)}
                    size="small"
                    fullWidth
                    options={optionsList}
                    disabled={disabled}
                    disableClearable
                    getOptionLabel={getOptionLabel}
                    clearIcon={null}
                    loadingText={'Chargement...'}
                    renderInput={renderInput}
                    renderOption={getOptionDisplay}
                />
            </FormControl>
        </div>
    );
}