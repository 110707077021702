import "./style/index.scss";
import {
  RouterProvider,
} from "react-router-dom";
import router from './router/router';
import { createTheme, ThemeProvider } from "@mui/material";

function App() {
  
  let theme = createTheme({
    typography: {
      fontFamily: [
        'Inter',
      ].join(','),
      fontSize: 14,
    },
    palette: {
      text: {
        primary: '#034D78',
      },
      primary: {
        main: '#034D78',
      },
      secondary: {
        main: '#1D2939',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
